import { system } from 'styled-system';

import type { ThemeTextAlign } from '@rover/kibble/styles';

import { createTransformWithDefault } from './transforms';

export type TextAlignProps = {
  textAlign?: ThemeTextAlign | ThemeTextAlign[];
};
const config = system({
  textAlign: {
    property: 'textAlign',
    scale: 'textAlign',
    transform: createTransformWithDefault('left'),
  },
});
export default config;
