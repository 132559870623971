import { I18N_FORMAT_MAP } from '@rover/kibble/styles';
import { DEFAULT_LANG } from '@rover/shared/js/constants/i18n/language';
import type { LocalizedFormatFunction } from '@rover/types';

import generateExternalFormatMap from './generateExternalFormatMap';

function generateFormatMap(
  getLocalizedFormat: LocalizedFormatFunction,
  language?: string
): Record<string, string> {
  return Object.keys(I18N_FORMAT_MAP[DEFAULT_LANG]).reduce((acc, key) => {
    acc[key] = getLocalizedFormat(key, language);
    return acc;
  }, generateExternalFormatMap(getLocalizedFormat, language));
}
export default generateFormatMap;
