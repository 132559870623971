import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import _trimEnd from 'lodash-es/trimEnd';
import { DefaultTheme, ThemeContext } from 'styled-components';

import type { ThemeFontWeight } from '@rover/kibble/styles';

export const useTheme = (): DefaultTheme | undefined => React.useContext(ThemeContext);

export function getNativeStyleFromArray(
  value: number | string | [string, string] | [string, string, string]
): string | number {
  if (!Array.isArray(value)) {
    return value;
  }
  return value[0];
}

export function pxToNumber(value: string | number): number {
  if (typeof value === 'number') return value;
  if (!value || !value.endsWith('px')) return 0;
  return parseInt(_trimEnd(value, 'px'), 10);
}

export type AvertaFontFamilyNames = 'Averta-Regular' | 'Averta-Semibold';
const convertFontWeight = (weight?: ThemeFontWeight): AvertaFontFamilyNames => {
  if (weight === 'semibold') {
    return 'Averta-Semibold';
  }
  return 'Averta-Regular';
};
export function fontWeightToFontFamily(
  weight?: ThemeFontWeight | ThemeFontWeight[]
): AvertaFontFamilyNames | AvertaFontFamilyNames[] {
  if (Array.isArray(weight)) {
    return weight.map(convertFontWeight);
  }
  return convertFontWeight(weight);
}

export { themeGet };
