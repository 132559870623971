/**
 * Do not edit directly
 * Generated on Mon, 03 Mar 2025 20:21:30 GMT
 */

import Color from './Color';

export const BACKGROUND_COLOR_PRIMARY = Color.NEUTRAL_WHITE;
export const BACKGROUND_COLOR_SECONDARY = Color.NEUTRAL_100;
export const BACKGROUND_COLOR_TERTIARY = Color.NEUTRAL_200;
export const BACKGROUND_COLOR_CONTRAST = Color.NEUTRAL_800;
export const BACKGROUND_COLOR_ACCENT = Color.YELLOW_100;
export const BACKGROUND_COLOR_ACCENT_SECONDARY = Color.BLUE_100;
export const BACKGROUND_COLOR_OVERLAY = Color.NEUTRAL_900.withOpacity(0.48);
export const BACKGROUND_COLOR_OVERLAY_ACCENT = Color.GREEN_500.withOpacity(0.24);
export const BACKGROUND_COLOR_ERROR = Color.RED_100;
export const BACKGROUND_COLOR_CAUTION = Color.YELLOW_100;
export const BACKGROUND_COLOR_SUCCESS = Color.GREEN_100;
export const BACKGROUND_COLOR_INFO = Color.NEUTRAL_100;
export const BACKGROUND_COLOR_HIGHLIGHT = Color.CYAN_100;
export const BACKGROUND_COLOR_ATTENTION_HIGHLIGHT = Color.YELLOW_400;
export const BACKGROUND_COLOR_ATTENTION_BLUE = Color.BLUE_200;
export const BORDER_COLOR_PRIMARY = Color.NEUTRAL_400;
export const BORDER_COLOR_SECONDARY = Color.NEUTRAL_300;
export const BORDER_COLOR_CONTRAST = Color.NEUTRAL_700;
export const BORDER_COLOR_ERROR = Color.RED_600;
export const BORDER_COLOR_CAUTION = Color.YELLOW_400;
export const BORDER_COLOR_SUCCESS = Color.GREEN_600;
export const BORDER_COLOR_INFO = Color.NEUTRAL_400;
export const BORDER_COLOR_HIGHLIGHT = Color.CYAN_700;
export const BORDER_COLOR_INPUT_FOCUS = Color.BLUE_600;
export const INTERACTIVE_COLOR_PRIMARY = Color.BLUE_600;
export const INTERACTIVE_COLOR_PRIMARY_PRESSED = Color.BLUE_700;
export const INTERACTIVE_BACKGROUND_COLOR_PRIMARY = Color.NEUTRAL_WHITE;
export const INTERACTIVE_BACKGROUND_COLOR_PRIMARY_PRESSED = Color.NEUTRAL_100;
export const INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE = Color.NEUTRAL_800;
export const INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE_PRESSED = Color.NEUTRAL_900;
export const INTERACTIVE_BACKGROUND_COLOR_BUTTON_PRIMARY = Color.BLUE_600;
export const INTERACTIVE_BACKGROUND_COLOR_BUTTON_PRIMARY_PRESSED = Color.BLUE_700;
export const INTERACTIVE_BACKGROUND_COLOR_DESTRUCTIVE = Color.RED_600;
export const INTERACTIVE_BACKGROUND_COLOR_DESTRUCTIVE_HOVER = Color.RED_700;
export const INTERACTIVE_BACKGROUND_COLOR_DISABLED = Color.NEUTRAL_100;
export const INTERACTIVE_BORDER_COLOR_PRIMARY = Color.NEUTRAL_400;
export const INTERACTIVE_BORDER_COLOR_PRIMARY_PRESSED = Color.NEUTRAL_600;
export const INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE = Color.NEUTRAL_800;
export const INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE_PRESSED = Color.NEUTRAL_900;
export const INTERACTIVE_BORDER_COLOR_DISABLED = Color.NEUTRAL_200;
export const INTERACTIVE_TEXT_COLOR_PRIMARY = Color.NEUTRAL_800;
export const INTERACTIVE_TEXT_COLOR_PRIMARY_ACTIVE = Color.BLUE_600;
export const INTERACTIVE_TEXT_COLOR_DISABLED = Color.NEUTRAL_500;
export const INTERACTIVE_TEXT_COLOR_DESTRUCTIVE = Color.RED_600;
export const INTERACTIVE_TEXT_COLOR_LINK_PRIMARY = Color.BLUE_600;
export const INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_PRESSED = Color.BLUE_700;
export const INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_INVERSE = Color.NEUTRAL_WHITE;
export const INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_INVERSE_PRESSED = Color.NEUTRAL_100;
export const INTERACTIVE_TEXT_COLOR_LINK_SECONDARY = Color.NEUTRAL_800;
export const INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_PRESSED = Color.NEUTRAL_900;
export const INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_INVERSE = Color.NEUTRAL_100;
export const INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_INVERSE_PRESSED = Color.NEUTRAL_200;
export const INTERACTIVE_LINK_COLOR_PRIMARY = Color.BLUE_600;
export const INTERACTIVE_LINK_COLOR_PRIMARY_HOVER = Color.BLUE_800;
export const INTERACTIVE_LINK_COLOR_PRIMARY_VISITED = Color.BLUE_600;
export const INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE = Color.BLUE_300;
export const INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE_HOVER = Color.BLUE_100;
export const INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE_VISITED = Color.BLUE_200;
export const INTERACTIVE_LINK_COLOR_SECONDARY = Color.NEUTRAL_700;
export const INTERACTIVE_LINK_COLOR_SECONDARY_HOVER = Color.NEUTRAL_900;
export const INTERACTIVE_LINK_COLOR_SECONDARY_VISITED = Color.BLUE_300;
export const INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE = Color.NEUTRAL_100;
export const INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE_HOVER = Color.NEUTRAL_300;
export const INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE_VISITED = Color.NEUTRAL_100;
export const SEPARATOR_COLOR_PRIMARY = Color.NEUTRAL_300;
export const TEMPORARY_COLOR_BUTTON_TERTIARY_BACKGROUND_PRESSED = Color.BLUE_400.withOpacity(0.24);
export const TEMPORARY_COLOR_BUTTON_DESTRUCTIVE_BACKGROUND_PRESSED =
  Color.RED_400.withOpacity(0.24);
export const TEMPORARY_COLOR_BUTTON_PRIMARY_DISABLED_BACKGROUND = Color.GREEN_600.withOpacity(0.24);
export const TEXT_COLOR_PRIMARY = Color.NEUTRAL_900;
export const TEXT_COLOR_PRIMARY_INVERSE = Color.NEUTRAL_WHITE;
export const TEXT_COLOR_SECONDARY = Color.NEUTRAL_800;
export const TEXT_COLOR_SECONDARY_INVERSE = Color.NEUTRAL_100;
export const TEXT_COLOR_TERTIARY = Color.NEUTRAL_700;
export const TEXT_COLOR_TERTIARY_INVERSE = Color.NEUTRAL_200;
export const TEXT_COLOR_PLACEHOLDER = Color.NEUTRAL_700;
export const TEXT_COLOR_DISABLED = Color.NEUTRAL_500;
export const TEXT_COLOR_PRICE = Color.ORANGE_700;
export const TEXT_COLOR_ERROR = Color.RED_600;
export const TEXT_COLOR_CAUTION = Color.YELLOW_800;
export const TEXT_COLOR_SUCCESS = Color.GREEN_700;
export const BORDER_RADIUS_PRIMARY = '8px';
export const BORDER_RADIUS_SECONDARY = '4px';
export const BORDER_RADIUS_ROUND = '99999px';
export const BORDER_WIDTH_PRIMARY = '2px';
export const BORDER_WIDTH_SECONDARY = '1px';
export const SPACE_NEGATIVE_64X = '-256px';
export const SPACE_NEGATIVE_48X = '-192px';
export const SPACE_NEGATIVE_40X = '-160px';
export const SPACE_NEGATIVE_32X = '-128px';
export const SPACE_NEGATIVE_24X = '-96px';
export const SPACE_NEGATIVE_20X = '-80px';
export const SPACE_NEGATIVE_16X = '-64px';
export const SPACE_NEGATIVE_12X = '-48px';
export const SPACE_NEGATIVE_10X = '-40px';
export const SPACE_NEGATIVE_8X = '-32px';
export const SPACE_NEGATIVE_6X = '-24px';
export const SPACE_NEGATIVE_5X = '-20px';
export const SPACE_NEGATIVE_4X = '-16px';
export const SPACE_NEGATIVE_3X = '-12px';
export const SPACE_NEGATIVE_2X = '-8px';
export const SPACE_NEGATIVE_1X = '-4px';
export const SPACE_0X = '0px';
export const SPACE_1X = '4px';
export const SPACE_2X = '8px';
export const SPACE_3X = '12px';
export const SPACE_4X = '16px';
export const SPACE_5X = '20px';
export const SPACE_6X = '24px';
export const SPACE_8X = '32px';
export const SPACE_10X = '40px';
export const SPACE_12X = '48px';
export const SPACE_16X = '64px';
export const SPACE_20X = '80px';
export const SPACE_24X = '96px';
export const SPACE_32X = '128px';
export const SPACE_40X = '160px';
export const SPACE_48X = '192px';
export const SPACE_64X = '256px';
export const DISPLAY_400_FONT_FAMILY = 'Bogart-Semibold';
export const DISPLAY_400_FONT_SIZE = '26px';
export const DISPLAY_400_FONT_WEIGHT = 700;
export const DISPLAY_400_LINE_HEIGHT = 1.25;
export const DISPLAY_400_MARGIN_BOTTOM = '20px';
export const DISPLAY_500_FONT_FAMILY = 'Bogart-Semibold';
export const DISPLAY_500_FONT_SIZE = '32px';
export const DISPLAY_500_FONT_WEIGHT = 700;
export const DISPLAY_500_LINE_HEIGHT = 1.25;
export const DISPLAY_500_MARGIN_BOTTOM = '24px';
export const DISPLAY_600_FONT_FAMILY = 'Bogart-Semibold';
export const DISPLAY_600_FONT_SIZE = '38px';
export const DISPLAY_600_FONT_WEIGHT = 700;
export const DISPLAY_600_LINE_HEIGHT = 1.25;
export const DISPLAY_600_MARGIN_BOTTOM = '32px';
export const DISPLAY_RESPONSIVE_400_FONT_SIZE = '32px';
export const DISPLAY_RESPONSIVE_500_FONT_SIZE = '40px';
export const DISPLAY_RESPONSIVE_600_FONT_SIZE = '48px';
export const HEADING_50_FONT_FAMILY = 'Averta';
export const HEADING_50_FONT_SIZE = '13px';
export const HEADING_50_FONT_WEIGHT = 700;
export const HEADING_50_LINE_HEIGHT = 1.25;
export const HEADING_50_MARGIN_BOTTOM = '8px';
export const HEADING_100_FONT_FAMILY = 'Averta';
export const HEADING_100_FONT_SIZE = '14px';
export const HEADING_100_FONT_WEIGHT = 700;
export const HEADING_100_LINE_HEIGHT = 1.25;
export const HEADING_100_MARGIN_BOTTOM = '8px';
export const HEADING_200_FONT_FAMILY = 'Averta';
export const HEADING_200_FONT_SIZE = '16px';
export const HEADING_200_FONT_WEIGHT = 700;
export const HEADING_200_LINE_HEIGHT = 1.25;
export const HEADING_200_MARGIN_BOTTOM = '12px';
export const HEADING_300_FONT_FAMILY = 'Averta';
export const HEADING_300_FONT_SIZE = '20px';
export const HEADING_300_FONT_WEIGHT = 700;
export const HEADING_300_LINE_HEIGHT = 1.25;
export const HEADING_300_MARGIN_BOTTOM = '16px';
export const HEADING_400_FONT_FAMILY = 'Averta';
export const HEADING_400_FONT_SIZE = '26px';
export const HEADING_400_FONT_WEIGHT = 700;
export const HEADING_400_LINE_HEIGHT = 1.25;
export const HEADING_400_MARGIN_BOTTOM = '20px';
export const HEADING_500_FONT_FAMILY = 'Averta';
export const HEADING_500_FONT_SIZE = '32px';
export const HEADING_500_FONT_WEIGHT = 700;
export const HEADING_500_LINE_HEIGHT = 1.25;
export const HEADING_500_MARGIN_BOTTOM = '24px';
export const HEADING_600_FONT_FAMILY = 'Averta';
export const HEADING_600_FONT_SIZE = '38px';
export const HEADING_600_FONT_WEIGHT = 700;
export const HEADING_600_LINE_HEIGHT = 1.25;
export const HEADING_600_MARGIN_BOTTOM = '32px';
export const HEADING_RESPONSIVE_400_FONT_SIZE = '32px';
export const HEADING_RESPONSIVE_500_FONT_SIZE = '40px';
export const HEADING_RESPONSIVE_600_FONT_SIZE = '48px';
export const PARAGRAPH_50_FONT_FAMILY = 'Averta';
export const PARAGRAPH_50_FONT_SIZE = '13px';
export const PARAGRAPH_50_FONT_WEIGHT = 400;
export const PARAGRAPH_50_LINE_HEIGHT = 1.5;
export const PARAGRAPH_50_MARGIN_BOTTOM = '12px';
export const PARAGRAPH_100_FONT_FAMILY = 'Averta';
export const PARAGRAPH_100_FONT_SIZE = '14px';
export const PARAGRAPH_100_FONT_WEIGHT = 400;
export const PARAGRAPH_100_LINE_HEIGHT = 1.5;
export const PARAGRAPH_100_MARGIN_BOTTOM = '12px';
export const PARAGRAPH_200_FONT_FAMILY = 'Averta';
export const PARAGRAPH_200_FONT_SIZE = '16px';
export const PARAGRAPH_200_FONT_WEIGHT = 400;
export const PARAGRAPH_200_LINE_HEIGHT = 1.5;
export const PARAGRAPH_200_MARGIN_BOTTOM = '16px';
export const PARAGRAPH_300_FONT_FAMILY = 'Averta';
export const PARAGRAPH_300_FONT_SIZE = '20px';
export const PARAGRAPH_300_FONT_WEIGHT = 400;
export const PARAGRAPH_300_LINE_HEIGHT = 1.5;
export const PARAGRAPH_300_MARGIN_BOTTOM = '24px';
export const TEXT_50_FONT_FAMILY = 'Averta';
export const TEXT_50_FONT_SIZE = '13px';
export const TEXT_50_FONT_WEIGHT = 400;
export const TEXT_50_LINE_HEIGHT = 1.25;
export const TEXT_100_FONT_FAMILY = 'Averta';
export const TEXT_100_FONT_SIZE = '14px';
export const TEXT_100_FONT_WEIGHT = 400;
export const TEXT_100_LINE_HEIGHT = 1.25;
export const TEXT_200_FONT_FAMILY = 'Averta';
export const TEXT_200_FONT_SIZE = '16px';
export const TEXT_200_FONT_WEIGHT = 400;
export const TEXT_200_LINE_HEIGHT = 1.5;
export const TEXT_300_FONT_FAMILY = 'Averta';
export const TEXT_300_FONT_SIZE = '20px';
export const TEXT_300_FONT_WEIGHT = 400;
export const TEXT_300_LINE_HEIGHT = 1.2;
