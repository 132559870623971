import type { I18nProviderPropType } from './modules/I18n';
import ObservabilityMetadata from './modules/ObservabilityMetadata';
import { Concept } from './concepts';

export type UseObservabilityMetadataConceptInfoArg = {
  concept?: Concept;
  uiComponentName?: string;
  uiName?: string;
};

export const useObservabilityMetadataConceptInfo = ({
  concept,
  uiComponentName,
  uiName,
}: UseObservabilityMetadataConceptInfoArg): void => {
  // we don't want to wrap this is useEffect because that can
  // delay execution and we want to set these values ASAP
  if (concept) {
    ObservabilityMetadata.set('concept', concept[0]);
    ObservabilityMetadata.set('team', concept[1]);
  }
  if (uiComponentName) {
    ObservabilityMetadata.set('uiComponentName', uiComponentName);
  }
  if (uiName) {
    ObservabilityMetadata.set('uiName', uiName);
  }
};

export type RSDKProviderProps = {
  children: React.ReactNode;
} & UseObservabilityMetadataConceptInfoArg &
  I18nProviderPropType;
