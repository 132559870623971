import React from 'react';
import { t } from '@lingui/macro';
import type { ButtonProps as RebassButtonProps, SxStyleProp } from 'rebass/styled-components';
import { Button as RebassButton } from 'rebass/styled-components';

import { Loading } from '@rover/icons';
import { Box, Flex, Text } from '@rover/kibble/core';
import { isProdEnv } from '@rover/rsdk/src/modules/Env/node';
import { Logger } from '@rover/rsdk/src/modules/Logging';

import {
  ButtonCommonProps,
  ButtonIconMarginMap,
  ButtonIconSizeMap,
  ButtonSizeTextSizeMap,
  ButtonYPaddingMap,
  getFullWidthPropForContainer,
  getVariant,
  getXPadding,
} from './Button.common';

export type Props = ButtonCommonProps &
  RebassButtonProps & {
    'aria-label'?: string;
    'data-qa-id'?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    role?: string;
    tabIndex?: number;
    type?: 'button' | 'submit' | 'reset';
    buttonRef?: React.Ref<HTMLButtonElement>;
  };

const ButtonIcon = ({
  icon: Icon,
  // the destructuring of flexShrink prevents errors from it being passed to an <svg />
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  flexShrink,
  ...other
}: {
  icon: React.ComponentType<unknown>;
  flexShrink: string;
}): JSX.Element => {
  return <Icon {...other} />;
};

function Button({
  children,
  contrast = false,
  circular = false,
  destructive = false,
  disabled = false,
  fullWidth = false,
  href,
  icon,
  iconAlignment = 'left',
  loading = false,
  onClick,
  size = 'default',
  type = 'button',
  variant = 'default',
  justifyContent = 'center',
  as,
  sx,
  buttonRef,
  ...other
}: Props): JSX.Element {
  if (!children && !other['aria-label'] && !other['aria-labelledby']) {
    const error = new Error('Button.props MUST have children, or a11y values');
    if (isProdEnv()) {
      Logger.error(error);
    } else {
      throw error;
    }
  }

  const isLink = Boolean(href);

  const commonIconProps = {
    fill: 'currentColor',
    height: ButtonIconSizeMap[size],
    width: ButtonIconSizeMap[size],
  };

  const styledIcon = icon ? (
    <Box {...commonIconProps} icon={icon} as={ButtonIcon} flexShrink="0" />
  ) : undefined;

  const styling: SxStyleProp = isLink ? { '&:focus, &:hover': { 'text-decoration': 'none' } } : {};

  return (
    <RebassButton
      variant={getVariant(disabled, destructive, contrast, variant)}
      disabled={disabled}
      px={getXPadding(size, circular)}
      py={ButtonYPaddingMap[size]}
      type={isLink ? undefined : type}
      as={as ?? (isLink ? 'a' : 'button')}
      href={disabled || !isLink ? undefined : href}
      onClick={onClick}
      sx={{ ...styling, ...getFullWidthPropForContainer(fullWidth), ...sx }}
      ref={buttonRef}
      {...other}
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent={justifyContent}
        gap={other.childrenFlexGap || ButtonIconMarginMap[size]}
      >
        {(!circular || !loading) && icon && iconAlignment === 'left' && styledIcon}
        {children && (
          <Text size={ButtonSizeTextSizeMap[size]} fontWeight="semibold">
            {children}
          </Text>
        )}
        {(!circular || !loading) && icon && iconAlignment === 'right' && styledIcon}
        {loading && (
          <Box
            flexShrink="0"
            height={ButtonIconSizeMap[size]}
            width={ButtonIconSizeMap[size]}
            data-testid="kibble-button-loading-spinner"
          >
            <Loading {...commonIconProps} />
          </Box>
        )}
      </Flex>
    </RebassButton>
  );
}

// just a placeholder string while there is no translation string for Kibble
// Smartling will error if we submit empty translation files
const submit = t`Submit`; // eslint-disable-line @typescript-eslint/no-unused-vars

export default Button;
