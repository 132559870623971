import { AriaRole, ForwardedRef } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { Box as RebassBox } from 'rebass/styled-components';
import styled from 'styled-components';
import { background, border, compose, position } from 'styled-system';

import { BoxCommonProps, PolymorphicBoxPropsHelper } from './Box.common';

export type Props = BoxCommonProps & {
  as?: React.ElementType;
  className?: string;
  ref?: ForwardedRef<HTMLElement>;
  style?: never; // style prop is forbidden, use the sx prop instead
  role?: AriaRole;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

const PROPS_TO_FILTER_FROM_DOM_ON_WEB = [
  'display',
  'width',
  'height',
  'size',
  'p',
  'px',
  'py',
  'pt',
  'pr',
  'pb',
  'pl',
  'm',
  'mx',
  'my',
  'mt',
  'mr',
  'mb',
  'ml',
  'tx',
];

const Box = styled(RebassBox).withConfig({
  shouldForwardProp: (prop, element) => {
    // string elements are DOM tags going into the HTML, we need to filter props on these
    if (typeof element === 'string') {
      if (PROPS_TO_FILTER_FROM_DOM_ON_WEB.includes(prop)) return false;
      return isPropValid(prop);
    }
    // non-string elements are React components, and we need to pass all props to these
    return true;
  },
})(compose(border, background, position)) as <C extends React.ElementType = 'div'>(
  props: PolymorphicBoxPropsHelper<Props, C>
) => JSX.Element;

export default Box;
