import React, { ComponentProps, useMemo } from 'react';
import { I18n as I18nType, MessageDescriptor } from '@lingui/core';
import { I18n, I18nProvider as LegacyI18nProvider } from '@lingui/react';

import { getDateTimeFormatMapForLang } from '@rover/shared/js/constants/i18n/datetime';

export type DateTimeFormatType = ReturnType<typeof getDateTimeFormatMapForLang>;

export type I18nContextType = {
  i18n: I18nType;
  datetimeFormatMap: DateTimeFormatType;
};

export const I18nContext = React.createContext<I18nContextType | undefined>(undefined);

type MemoizedI18nProviderProps = I18nContextType & {
  children: React.ReactNode;
};
const MemoizedI18nProvider = ({
  children,
  i18n,
  datetimeFormatMap,
}: MemoizedI18nProviderProps): JSX.Element => {
  const i18nContextValue = useMemo(
    () => ({
      i18n,
      datetimeFormatMap,
    }),
    [i18n, datetimeFormatMap]
  );
  return <I18nContext.Provider value={i18nContextValue}>{children}</I18nContext.Provider>;
};

export type I18nProviderPropType = ComponentProps<typeof LegacyI18nProvider>;
export const I18nProvider = ({ children, ...i18nConfig }: I18nProviderPropType): JSX.Element => {
  return (
    <LegacyI18nProvider {...i18nConfig}>
      <I18n>
        {({ i18n }): JSX.Element => {
          const datetimeFormatMap = getDateTimeFormatMapForLang(i18n.language);
          return (
            <MemoizedI18nProvider i18n={i18n} datetimeFormatMap={datetimeFormatMap}>
              {children}
            </MemoizedI18nProvider>
          );
        }}
      </I18n>
    </LegacyI18nProvider>
  );
};

type I18nHookValue = {
  i18n: I18nType;
  locale: string;
  DF: DateTimeFormatType;
  /**
   * Cheesy little helper to make it easier to accept either a string or a MessageDescriptor
   */
  _s: (s: MessageDescriptor | string) => string;
};

export function useI18n(): I18nHookValue {
  const i18nContext = React.useContext(I18nContext);
  if (!i18nContext) throw new Error(`I18nProvider is not setup properly`);
  const { i18n, datetimeFormatMap } = i18nContext;
  return {
    i18n,
    locale: i18n.language,
    DF: datetimeFormatMap,
    _s: (s) => (typeof s === 'string' ? s : i18n._(s)),
  };
}
export type { I18nType };
