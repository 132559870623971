/**
 * Do not edit directly
 * Generated on Mon, 03 Mar 2025 20:21:30 GMT
 */

import Enum from '@rover/types/src/Enum';

type ContrastRating = 'AAA' | 'AA' | '•AA' | 'DNP';

type ColorType = {
  name: string;
  rgba: { r: number; g: number; b: number; a: number };
  hex: string;
  blackTextContrastRating?: ContrastRating;
  whiteTextContrastRating?: ContrastRating;
};

class Color extends Enum<ColorType> {
  static BLUE_100 = new Color({
    name: 'BLUE_100',
    rgba: { r: 236, g: 241, b: 251, a: 1 },
    hex: '#ECF1FB',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static BLUE_200 = new Color({
    name: 'BLUE_200',
    rgba: { r: 197, g: 213, b: 242, a: 1 },
    hex: '#C5D5F2',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static BLUE_300 = new Color({
    name: 'BLUE_300',
    rgba: { r: 163, g: 189, b: 235, a: 1 },
    hex: '#A3BDEB',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static BLUE_400 = new Color({
    name: 'BLUE_400',
    rgba: { r: 125, g: 161, b: 226, a: 1 },
    hex: '#7DA1E2',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: '•AA',
  });

  static BLUE_500 = new Color({
    name: 'BLUE_500',
    rgba: { r: 86, g: 133, b: 218, a: 1 },
    hex: '#5685DA',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: 'AA',
  });

  static BLUE_600 = new Color({
    name: 'BLUE_600',
    rgba: { r: 46, g: 103, b: 209, a: 1 },
    hex: '#2E67D1',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AA',
  });

  static BLUE_700 = new Color({
    name: 'BLUE_700',
    rgba: { r: 45, g: 92, b: 177, a: 1 },
    hex: '#2D5CB1',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static BLUE_800 = new Color({
    name: 'BLUE_800',
    rgba: { r: 36, g: 73, b: 140, a: 1 },
    hex: '#24498C',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static BLUE_900 = new Color({
    name: 'BLUE_900',
    rgba: { r: 23, g: 47, b: 91, a: 1 },
    hex: '#172F5B',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static CYAN_100 = new Color({
    name: 'CYAN_100',
    rgba: { r: 232, g: 249, b: 252, a: 1 },
    hex: '#E8F9FC',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static CYAN_200 = new Color({
    name: 'CYAN_200',
    rgba: { r: 139, g: 226, b: 239, a: 1 },
    hex: '#8BE2EF',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static CYAN_300 = new Color({
    name: 'CYAN_300',
    rgba: { r: 57, g: 205, b: 228, a: 1 },
    hex: '#39CDE4',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static CYAN_400 = new Color({
    name: 'CYAN_400',
    rgba: { r: 28, g: 176, b: 199, a: 1 },
    hex: '#1CB0C7',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static CYAN_500 = new Color({
    name: 'CYAN_500',
    rgba: { r: 24, g: 147, b: 167, a: 1 },
    hex: '#1893A7',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static CYAN_600 = new Color({
    name: 'CYAN_600',
    rgba: { r: 18, g: 119, b: 135, a: 1 },
    hex: '#127787',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: '•AA',
  });

  static CYAN_700 = new Color({
    name: 'CYAN_700',
    rgba: { r: 17, g: 104, b: 118, a: 1 },
    hex: '#116876',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: 'AA',
  });

  static CYAN_800 = new Color({
    name: 'CYAN_800',
    rgba: { r: 13, g: 83, b: 93, a: 1 },
    hex: '#0D535D',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static CYAN_900 = new Color({
    name: 'CYAN_900',
    rgba: { r: 9, g: 54, b: 61, a: 1 },
    hex: '#09363D',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static GREEN_100 = new Color({
    name: 'GREEN_100',
    rgba: { r: 241, g: 253, b: 246, a: 1 },
    hex: '#F1FDF6',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static GREEN_200 = new Color({
    name: 'GREEN_200',
    rgba: { r: 186, g: 232, b: 201, a: 1 },
    hex: '#BAE8C9',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static GREEN_300 = new Color({
    name: 'GREEN_300',
    rgba: { r: 107, g: 208, b: 148, a: 1 },
    hex: '#6BD094',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static GREEN_400 = new Color({
    name: 'GREEN_400',
    rgba: { r: 5, g: 184, b: 108, a: 1 },
    hex: '#05B86C',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static GREEN_500 = new Color({
    name: 'GREEN_500',
    rgba: { r: 22, g: 154, b: 91, a: 1 },
    hex: '#169A5B',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static GREEN_600 = new Color({
    name: 'GREEN_600',
    rgba: { r: 26, g: 130, b: 78, a: 1 },
    hex: '#1A824E',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: '•AA',
  });

  static GREEN_700 = new Color({
    name: 'GREEN_700',
    rgba: { r: 27, g: 108, b: 66, a: 1 },
    hex: '#1B6C42',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AA',
  });

  static GREEN_800 = new Color({
    name: 'GREEN_800',
    rgba: { r: 27, g: 85, b: 53, a: 1 },
    hex: '#1B5535',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static GREEN_900 = new Color({
    name: 'GREEN_900',
    rgba: { r: 23, g: 55, b: 36, a: 1 },
    hex: '#173724',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static ORANGE_100 = new Color({
    name: 'ORANGE_100',
    rgba: { r: 252, g: 245, b: 239, a: 1 },
    hex: '#FCF5EF',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static ORANGE_200 = new Color({
    name: 'ORANGE_200',
    rgba: { r: 255, g: 212, b: 168, a: 1 },
    hex: '#FFD4A8',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static ORANGE_300 = new Color({
    name: 'ORANGE_300',
    rgba: { r: 255, g: 169, b: 110, a: 1 },
    hex: '#FFA96E',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static ORANGE_400 = new Color({
    name: 'ORANGE_400',
    rgba: { r: 255, g: 138, b: 70, a: 1 },
    hex: '#FF8A46',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static ORANGE_500 = new Color({
    name: 'ORANGE_500',
    rgba: { r: 255, g: 117, b: 37, a: 1 },
    hex: '#FF7525',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static ORANGE_600 = new Color({
    name: 'ORANGE_600',
    rgba: { r: 224, g: 98, b: 27, a: 1 },
    hex: '#E0621B',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: '•AA',
  });

  static ORANGE_700 = new Color({
    name: 'ORANGE_700',
    rgba: { r: 187, g: 79, b: 18, a: 1 },
    hex: '#BB4F12',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: 'AA',
  });

  static ORANGE_800 = new Color({
    name: 'ORANGE_800',
    rgba: { r: 119, g: 50, b: 11, a: 1 },
    hex: '#77320B',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static ORANGE_900 = new Color({
    name: 'ORANGE_900',
    rgba: { r: 51, g: 23, b: 6, a: 1 },
    hex: '#331706',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static PINK_100 = new Color({
    name: 'PINK_100',
    rgba: { r: 255, g: 242, b: 247, a: 1 },
    hex: '#FFF2F7',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static PINK_200 = new Color({
    name: 'PINK_200',
    rgba: { r: 255, g: 214, b: 232, a: 1 },
    hex: '#FFD6E8',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static PINK_300 = new Color({
    name: 'PINK_300',
    rgba: { r: 255, g: 166, b: 204, a: 1 },
    hex: '#FFA6CC',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static PINK_400 = new Color({
    name: 'PINK_400',
    rgba: { r: 255, g: 125, b: 177, a: 1 },
    hex: '#FF7DB1',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static PINK_500 = new Color({
    name: 'PINK_500',
    rgba: { r: 255, g: 102, b: 163, a: 1 },
    hex: '#FF66A3',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static PINK_600 = new Color({
    name: 'PINK_600',
    rgba: { r: 209, g: 56, b: 128, a: 1 },
    hex: '#D13880',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: 'AA',
  });

  static PINK_700 = new Color({
    name: 'PINK_700',
    rgba: { r: 153, g: 33, b: 95, a: 1 },
    hex: '#99215F',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static PINK_800 = new Color({
    name: 'PINK_800',
    rgba: { r: 51, g: 10, b: 34, a: 1 },
    hex: '#330A22',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static PINK_900 = new Color({
    name: 'PINK_900',
    rgba: { r: 51, g: 23, b: 6, a: 1 },
    hex: '#331706',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static RED_100 = new Color({
    name: 'RED_100',
    rgba: { r: 255, g: 237, b: 232, a: 1 },
    hex: '#FFEDE8',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static RED_200 = new Color({
    name: 'RED_200',
    rgba: { r: 255, g: 200, b: 188, a: 1 },
    hex: '#FFC8BC',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static RED_300 = new Color({
    name: 'RED_300',
    rgba: { r: 255, g: 164, b: 148, a: 1 },
    hex: '#FFA494',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static RED_400 = new Color({
    name: 'RED_400',
    rgba: { r: 255, g: 118, b: 101, a: 1 },
    hex: '#FF7665',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static RED_500 = new Color({
    name: 'RED_500',
    rgba: { r: 230, g: 86, b: 74, a: 1 },
    hex: '#E6564A',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: '•AA',
  });

  static RED_600 = new Color({
    name: 'RED_600',
    rgba: { r: 188, g: 67, b: 56, a: 1 },
    hex: '#BC4338',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AA',
  });

  static RED_700 = new Color({
    name: 'RED_700',
    rgba: { r: 160, g: 63, b: 55, a: 1 },
    hex: '#A03F37',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static RED_800 = new Color({
    name: 'RED_800',
    rgba: { r: 125, g: 52, b: 45, a: 1 },
    hex: '#7D342D',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static RED_900 = new Color({
    name: 'RED_900',
    rgba: { r: 79, g: 35, b: 31, a: 1 },
    hex: '#4F231F',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static YELLOW_100 = new Color({
    name: 'YELLOW_100',
    rgba: { r: 252, g: 246, b: 235, a: 1 },
    hex: '#FCF6EB',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static YELLOW_200 = new Color({
    name: 'YELLOW_200',
    rgba: { r: 255, g: 236, b: 189, a: 1 },
    hex: '#FFECBD',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static YELLOW_300 = new Color({
    name: 'YELLOW_300',
    rgba: { r: 255, g: 215, b: 106, a: 1 },
    hex: '#FFD76A',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static YELLOW_400 = new Color({
    name: 'YELLOW_400',
    rgba: { r: 248, g: 184, b: 22, a: 1 },
    hex: '#F8B816',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static YELLOW_500 = new Color({
    name: 'YELLOW_500',
    rgba: { r: 213, g: 148, b: 24, a: 1 },
    hex: '#D59418',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static YELLOW_600 = new Color({
    name: 'YELLOW_600',
    rgba: { r: 183, g: 127, b: 29, a: 1 },
    hex: '#B77F1D',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static YELLOW_700 = new Color({
    name: 'YELLOW_700',
    rgba: { r: 128, g: 86, b: 26, a: 1 },
    hex: '#80561A',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: 'AA',
  });

  static YELLOW_800 = new Color({
    name: 'YELLOW_800',
    rgba: { r: 101, g: 68, b: 24, a: 1 },
    hex: '#654418',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static YELLOW_900 = new Color({
    name: 'YELLOW_900',
    rgba: { r: 65, g: 44, b: 19, a: 1 },
    hex: '#412C13',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static NEUTRAL_100 = new Color({
    name: 'NEUTRAL_100',
    rgba: { r: 244, g: 245, b: 246, a: 1 },
    hex: '#F4F5F6',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static NEUTRAL_200 = new Color({
    name: 'NEUTRAL_200',
    rgba: { r: 230, g: 232, b: 235, a: 1 },
    hex: '#E6E8EB',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static NEUTRAL_300 = new Color({
    name: 'NEUTRAL_300',
    rgba: { r: 215, g: 220, b: 224, a: 1 },
    hex: '#D7DCE0',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static NEUTRAL_400 = new Color({
    name: 'NEUTRAL_400',
    rgba: { r: 201, g: 207, b: 212, a: 1 },
    hex: '#C9CFD4',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static NEUTRAL_500 = new Color({
    name: 'NEUTRAL_500',
    rgba: { r: 158, g: 165, b: 172, a: 1 },
    hex: '#9EA5AC',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static NEUTRAL_600 = new Color({
    name: 'NEUTRAL_600',
    rgba: { r: 118, g: 124, b: 130, a: 1 },
    hex: '#767C82',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: '•AA',
  });

  static NEUTRAL_700 = new Color({
    name: 'NEUTRAL_700',
    rgba: { r: 98, g: 104, b: 110, a: 1 },
    hex: '#62686E',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AA',
  });

  static NEUTRAL_800 = new Color({
    name: 'NEUTRAL_800',
    rgba: { r: 64, g: 67, b: 71, a: 1 },
    hex: '#404347',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static NEUTRAL_900 = new Color({
    name: 'NEUTRAL_900',
    rgba: { r: 31, g: 33, b: 36, a: 1 },
    hex: '#1F2124',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static NEUTRAL_WHITE = new Color({
    name: 'NEUTRAL_WHITE',
    rgba: { r: 255, g: 255, b: 255, a: 1 },
    hex: '#FFFFFF',
    blackTextContrastRating: 'AAA',
    whiteTextContrastRating: 'DNP',
  });

  static NEUTRAL_BLACK = new Color({
    name: 'NEUTRAL_BLACK',
    rgba: { r: 0, g: 0, b: 0, a: 1 },
    hex: '#000000',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static BRAND_ROVER = new Color({
    name: 'BRAND_ROVER',
    rgba: { r: 0, g: 189, b: 112, a: 1 },
    hex: '#00BD70',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static BRAND_DOGBUDDY = new Color({
    name: 'BRAND_DOGBUDDY',
    rgba: { r: 255, g: 90, b: 91, a: 1 },
    hex: '#FF5A5B',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: '•AA',
  });

  static BRAND_FACEBOOK = new Color({
    name: 'BRAND_FACEBOOK',
    rgba: { r: 59, g: 89, b: 152, a: 1 },
    hex: '#3B5998',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: '•AA',
  });

  static BRAND_INSTAGRAM = new Color({
    name: 'BRAND_INSTAGRAM',
    rgba: { r: 138, g: 58, b: 185, a: 1 },
    hex: '#8A3AB9',
    blackTextContrastRating: '•AA',
    whiteTextContrastRating: 'AA',
  });

  static BRAND_TWITTER = new Color({
    name: 'BRAND_TWITTER',
    rgba: { r: 29, g: 161, b: 242, a: 1 },
    hex: '#1DA1F2',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static BRAND_X = new Color({
    name: 'BRAND_X',
    rgba: { r: 0, g: 0, b: 0, a: 1 },
    hex: '#000000',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: 'DNP',
  });

  static BRAND_PINTEREST = new Color({
    name: 'BRAND_PINTEREST',
    rgba: { r: 200, g: 35, b: 44, a: 1 },
    hex: '#C8232C',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AA',
  });

  static BRAND_GOOGLE = new Color({
    name: 'BRAND_GOOGLE',
    rgba: { r: 66, g: 133, b: 244, a: 1 },
    hex: '#4285F4',
    blackTextContrastRating: 'AA',
    whiteTextContrastRating: '•AA',
  });

  static BRAND_APPLE = new Color({
    name: 'BRAND_APPLE',
    rgba: { r: 0, g: 0, b: 0, a: 1 },
    hex: '#000000',
    blackTextContrastRating: 'DNP',
    whiteTextContrastRating: 'AAA',
  });

  static isLocked = true;

  toString(): string {
    const { r, g, b, a } = this.value.rgba;
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  toTestString(): string {
    return this.toString().replace(/\s/g, ' ');
  }

  toHexString(): string {
    return this.value.hex;
  }

  withOpacity(opacity: number): string {
    const { r, g, b } = this.value.rgba;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
export default Color;
