import React from 'react';

import { themeGet, useTheme } from '@rover/kibble/utils';

import Box from '../Box';

import { FlexCommonProps } from './Flex.common';

export type Props = React.ComponentProps<typeof Box> & FlexCommonProps;

function Flex({ gap, sx, htmlRef, ...props }: Props): JSX.Element {
  const theme = useTheme();
  const gapValue = themeGet(`space.${gap}`, gap)({ theme });

  return <Box ref={htmlRef} display="flex" {...props} sx={{ gap: gapValue, ...sx }} />;
}

export default Flex;
