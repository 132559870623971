import type { LocalizedFormatFunction } from '@rover/types';

function generateExternalFormatMap(
  getLocalizedFormat: LocalizedFormatFunction,
  language?: string
): Record<string, string> {
  return {
    'M/DD': getLocalizedFormat('MONTH_DAY_SHORT', language),
  };
}

export default generateExternalFormatMap;
