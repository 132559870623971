import { DEFAULT_LANG } from '@rover/shared/js/constants/i18n/language';

import getDateTimeFormatForLang from './datetimeContextless';

export const defaultDateTimeFormatMap = getDateTimeFormatForLang(DEFAULT_LANG);
export {
  default,
  getExternalFormatMap,
  getDateTimeFormatMapForLang,
  I18N_FORMAT_MAP,
} from './datetimeContextless';
