import { I18N_FORMAT_MAP } from '@rover/kibble/styles';

import generateExternalFormatMap from './generateExternalFormatMap';
import generateFormatMapForLang from './generateFormatMap';
import getLocalizedFormatContextless from './getLocalizedFormatContextless';

const getExternalFormatMap = (language: string): Record<string, string> =>
  generateExternalFormatMap(getLocalizedFormatContextless, language);

export const getDateTimeFormatMapForLang = (language: string): Record<string, string> =>
  generateFormatMapForLang(getLocalizedFormatContextless, language);

export {
  getDateTimeFormatMapForLang as default,
  getLocalizedFormatContextless as getLocalizedFormat,
  getExternalFormatMap,
  I18N_FORMAT_MAP,
};
