import React, { AriaRole } from 'react';
import styled from 'styled-components';
import { compose, space, variant } from 'styled-system';

import {
  DSTokenMap,
  fontWeight,
  textAlign,
  textColor,
  textDecoration,
  textTransform,
} from '@rover/kibble/styles';

import Box from '../Box';
import { PolymorphicBoxPropsHelper } from '../Box/Box.common';

import { TextCommonProps } from './Text.common';

export type TextNodes = 'span' | 'div' | 'label' | 'legend' | 'caption';

export type Props = TextCommonProps & {
  id?: string;
  as?: TextNodes;
  htmlFor?: string;
  testID?: string;
  role?: AriaRole;
};

const StyledText = styled(Box)<Omit<Props, 'as'>>(
  // Resets values in case of global styles
  {
    padding: '0',
    margin: '0',
    border: '0 none',
  },
  variant({
    prop: 'size',
    variants: {
      // TODO: move these styles to Style Dictionary to format into theme.ts
      50: {
        fontSize: DSTokenMap.TEXT_50_FONT_SIZE,
        lineHeight: DSTokenMap.TEXT_50_LINE_HEIGHT,
      },
      100: {
        fontSize: DSTokenMap.TEXT_100_FONT_SIZE,
        lineHeight: DSTokenMap.TEXT_100_LINE_HEIGHT,
      },
      200: {
        fontSize: DSTokenMap.TEXT_200_FONT_SIZE,
        lineHeight: DSTokenMap.TEXT_200_LINE_HEIGHT,
      },
      300: {
        fontSize: DSTokenMap.TEXT_300_FONT_SIZE,
        lineHeight: DSTokenMap.TEXT_300_LINE_HEIGHT,
      },
    },
  }),
  compose(textAlign, textColor, fontWeight, textDecoration, textTransform, space) // Apply last to avoid being overwritten by resets and variant styles
);

function Text<C extends React.ElementType = 'span'>({
  as,
  size = '200',
  ...other
}: PolymorphicBoxPropsHelper<Props, C>): JSX.Element {
  const Component = as || 'span';
  return <StyledText {...other} as={Component} size={size} />;
}

export default Text;
