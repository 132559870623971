import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StyleSheetManager } from 'styled-components';

import { decodeHtmlNodes } from '@rover/utilities/json';

import { I18nProvider } from './modules/I18n';
import { RSDKProviderProps, useObservabilityMetadataConceptInfo } from './index.common';

const RSDKEventBridge = (): JSX.Element | null => {
  return null;
};

let clientSideQueryClient: QueryClient | undefined;
let dehydratedStateFromWindowGlobal: DehydratedState | undefined;
if (process.env.JS_ENV_CLIENT || process.env.JEST_WORKER_ID) {
  clientSideQueryClient = new QueryClient();
  // @ts-expect-error this global is templated in by generateScripts.ts
  const dehydratedStateFromWindowGlobal = decodeHtmlNodes(window.__REACT_QUERY_STATE__ || {}); // eslint-disable-line
}

type SSRAndWebRSDKProviderProps = RSDKProviderProps & {
  queryClient?: QueryClient;
  dehydratedState?: DehydratedState;
  shouldRenderReactQueryDevTools?: boolean;
};

export const handleShouldForwardProp: React.ComponentProps<
  typeof StyleSheetManager
>['shouldForwardProp'] = (prop, element) => {
  // string elements are DOM tags going into the HTML, we need to filter props on these
  if (typeof element === 'string') return isPropValid(prop);
  // special case for our @rover/icons components which need to filter props as well
  if (element.name && element.name.startsWith('Svg')) return isPropValid(prop);
  // non-string elements are React components, and we need to pass all props to these
  return true;
};

// RSDK general provider
export function RSDKProviders({
  children,
  concept,
  dehydratedState,
  queryClient,
  shouldRenderReactQueryDevTools = true,
  uiComponentName,
  uiName,
  ...i18nConfig
}: SSRAndWebRSDKProviderProps): JSX.Element {
  useObservabilityMetadataConceptInfo({ concept, uiComponentName, uiName });

  // guard against missing QueryClient instance
  const queryClientToUse = queryClient || clientSideQueryClient;
  if (!queryClientToUse) {
    throw new Error('RSDKProviders missing queryClient and clientSideQueryClient');
  }

  return (
    <I18nProvider {...i18nConfig}>
      <QueryClientProvider client={queryClientToUse}>
        <Hydrate state={dehydratedState || dehydratedStateFromWindowGlobal || {}}>
          <StyleSheetManager shouldForwardProp={handleShouldForwardProp}>
            {children}
            {shouldRenderReactQueryDevTools && <ReactQueryDevtools />}
          </StyleSheetManager>
        </Hydrate>
      </QueryClientProvider>
    </I18nProvider>
  );
}

// Actionsheet
export { useActionSheet } from './utils';

// RSDK event bridge
export default {
  moduleName: 'rsdk-internal',
  Component: RSDKEventBridge,
};
