import he from 'he';

type TransformFn = (value: string) => string;

export const traverseJson = <T>(obj: T, transformFn: TransformFn): T => {
  if (typeof obj === 'string') {
    return transformFn(obj) as T;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => traverseJson(item, transformFn)) as T;
  }

  if (typeof obj === 'object' && obj !== null) {
    const result: { [key: string]: any } = {};

    for (const key of Object.keys(obj)) {
      result[key] = traverseJson(obj[key], transformFn);
    }

    return result as T;
  }

  return obj;
};

export const decodeHtmlNodes = <T>(obj: T): T => {
  return traverseJson(obj, he.decode);
};

export const encodeHtmlNodes = <T>(obj: T): T => {
  return traverseJson(obj, he.encode);
};
