export const createTransformWithDefault = (defaultValue: string | number | void) => (
  propValue,
  scale
) => {
  if (!scale) {
    return defaultValue;
  }

  return scale[propValue] || defaultValue;
};
