import { ComponentType } from 'react';
import { SxStyleProp } from 'rebass/styled-components';

import { ThemeSpacing, ThemeTextSizes } from '../../styles';
import { MarginSystemProps, SizeSystemType, StringSystemType } from '../styledSystemPropTypes';

export type ButtonSizes = 'small' | 'default' | 'large';
export type ButtonVariants = 'default' | 'primary' | 'flat';
export type ButtonSizeMap<T> = {
  small: T;
  default: T;
  large: T;
};

// Height of the button is based off of the line height plus top and bottom padding
export const ButtonYPaddingMap: ButtonSizeMap<ThemeSpacing> = {
  small: '2x',
  default: '3x',
  large: '5x',
};

export const ButtonXPaddingMap: ButtonSizeMap<ThemeSpacing> = {
  small: '4x',
  default: '6x',
  large: '8x',
};

export const ButtonSizeTextSizeMap: ButtonSizeMap<ThemeTextSizes> = {
  small: '100',
  default: '200',
  large: '300',
};

export const ButtonIconSizeMap: ButtonSizeMap<string> = {
  small: '16px',
  default: '24px',
  large: '24px',
};

export const ButtonIconMarginMap: ButtonSizeMap<ThemeSpacing> = {
  small: '2x',
  default: '3x',
  large: '4x',
};

export type ButtonCommonProps = MarginSystemProps & {
  alignSelf?: StringSystemType;
  children?: React.ReactNode;
  childrenFlexGap?: string;
  circular?: boolean;
  contrast?: boolean;
  destructive?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  icon?: ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  iconAlignment?: 'left' | 'right';
  loading?: boolean;
  size?: ButtonSizes;
  variant?: ButtonVariants;
  width?: SizeSystemType;
  justifyContent?: StringSystemType;
  sx?:
    | SxStyleProp
    | Record<string, string | number | undefined | Array<string | number | undefined>>;
  testID?: string;
  padding?: string;
  fontWeight?: string;
};

export function getVariant(
  disabled: boolean,
  destructive: boolean,
  contrast: boolean,
  variant: ButtonVariants = 'default',
  // only used on native to provide press styles
  isPressed: boolean = false
): string {
  // disabled styles have top priority and no pressed state
  if (disabled) return 'disabled';

  let resolvedVariant = `${variant}.base`;
  if (contrast && destructive) {
    resolvedVariant = `${variant}.contrastDestructive`;
  } else if (contrast) {
    resolvedVariant = `${variant}.contrast`;
  } else if (destructive) {
    resolvedVariant = `${variant}.destructive`;
  }

  // if pressed, append `_pressed` to the resolved variant.  Should only happen on native!
  return isPressed ? `${resolvedVariant}_pressed` : resolvedVariant;
}

export function getXPadding(size: ButtonSizes, circular: boolean): ThemeSpacing {
  return circular ? ButtonYPaddingMap[size] : ButtonXPaddingMap[size];
}

export function getFullWidthPropForContainer(fullWidth: boolean):
  | {
      width: '100%';
    }
  | {
      alignSelf: 'center';
    } {
  return fullWidth
    ? {
        width: '100%',
      }
    : {
        alignSelf: 'center',
      };
}
