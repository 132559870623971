export default class Enum<T> {
  static isLocked: boolean;

  value: T;

  constructor(value: T) {
    // @ts-expect-error
    if (this.constructor.isLocked) {
      throw new Error(
        `You attempted to add a property to an Enum after it was locked: ${String(value)}`
      );
    }

    if (typeof value === 'undefined') {
      throw new Error('Cannot define an Enum type with no value');
    }

    this.value = value;
  }

  toString(): string {
    return String(this.value);
  }

  static fromValue<V = any>(value: V | undefined): Enum<V> | undefined {
    if (value === undefined) return undefined;
    let found;

    Object.keys(this).forEach((key: string) => {
      if (this[key].value === value) {
        found = this[key];
      }
    });

    return found;
  }

  static values<V = any>(): Array<Enum<V>> {
    return Object.keys(this).reduce(
      (acc: Array<Enum<V>>, key: string) => (this[key] instanceof this ? [...acc, this[key]] : acc),
      []
    );
  }
}
