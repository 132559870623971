/**
 * Do not edit directly
 * Generated on Mon, 03 Mar 2025 20:21:30 GMT
 */

import { css } from 'styled-components';

export type Spacing =
  | '-64x'
  | '-48x'
  | '-40x'
  | '-32x'
  | '-24x'
  | '-20x'
  | '-16x'
  | '-12x'
  | '-10x'
  | '-8x'
  | '-6x'
  | '-5x'
  | '-4x'
  | '-3x'
  | '-2x'
  | '-1x'
  | '0x'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '8x'
  | '10x'
  | '12x'
  | '16x'
  | '20x'
  | '24x'
  | '32x'
  | '40x'
  | '48x'
  | '64x';
export type Radii = 'primary' | 'secondary' | 'round';
export type FontWeight = 'regular' | 'semibold';
export type TextAlign = 'left' | 'center' | 'right';
export type TextColor =
  | 'primary'
  | 'primary_inverse'
  | 'secondary'
  | 'secondary_inverse'
  | 'tertiary'
  | 'tertiary_inverse'
  | 'placeholder'
  | 'disabled'
  | 'price'
  | 'error'
  | 'caution'
  | 'success';
export type TextDecoration = 'none' | 'underline' | 'line-through';
export type TextTransform = 'none' | 'uppercase';
export type HeadingSizes = '50' | '100' | '200' | '300' | '400' | '500' | '600';
export type ResponsiveHeadingSizes = '400' | '500' | '600';
export type DisplaySizes = '400' | '500' | '600';
export type ResponsiveDisplaySizes = '400' | '500' | '600';
export type ParagraphSizes = '50' | '100' | '200' | '300';
export type TextSizes = '50' | '100' | '200' | '300';
export type BackgroundColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'contrast'
  | 'accent'
  | 'accent_secondary'
  | 'overlay'
  | 'overlay_accent'
  | 'error'
  | 'caution'
  | 'success'
  | 'info'
  | 'highlight'
  | 'attention_highlight'
  | 'attention_blue'
  | 'interactive.primary_base'
  | 'interactive.primary_pressed'
  | 'interactive.primary_active'
  | 'interactive.primary_active_pressed'
  | 'interactive.button_primary_base'
  | 'interactive.button_primary_pressed'
  | 'interactive.destructive_base'
  | 'interactive.destructive_hover'
  | 'interactive.disabled_base';

export const WEB_FALLBACK_FONT_FAMILIES = 'Avenir, Helvetica Neue, Helvetica, sans-serif';

export type HeadingStyles = { [key in HeadingSizes]: any };
export const headingStyles: HeadingStyles = {
  '50': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 8px;
  `,
  '100': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 8px;
  `,
  '200': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 12px;
  `,
  '300': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 16px;
  `,
  '400': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 26px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 20px;
  `,
  '500': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 24px;
  `,
  '600': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 38px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 32px;
  `,
};
export type ResponsiveHeadingStyles = { [key in ResponsiveHeadingSizes]: any };
export const responsiveHeadingStyles: ResponsiveHeadingStyles = {
  '400': css`
    font-size: 32px;
  `,
  '500': css`
    font-size: 40px;
  `,
  '600': css`
    font-size: 48px;
  `,
};
export type DisplayStyles = { [key in DisplaySizes]: any };
export const displayStyles: DisplayStyles = {
  '400': css`
    font-family: Bogart-Semibold, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 26px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 20px;
  `,
  '500': css`
    font-family: Bogart-Semibold, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 24px;
  `,
  '600': css`
    font-family: Bogart-Semibold, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 38px;
    font-weight: 700 !important;
    line-height: 1.25;
    margin-bottom: 32px;
  `,
};
export type ResponsiveDisplayStyles = { [key in ResponsiveDisplaySizes]: any };
export const responsiveDisplayStyles: ResponsiveDisplayStyles = {
  '400': css`
    font-size: 32px;
  `,
  '500': css`
    font-size: 40px;
  `,
  '600': css`
    font-size: 48px;
  `,
};
export type ParagraphStyles = { [key in ParagraphSizes]: any };
export const paragraphStyles: ParagraphStyles = {
  '50': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 400 !important;
    line-height: 1.5;
    margin-bottom: 12px;
  `,
  '100': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 1.5;
    margin-bottom: 12px;
  `,
  '200': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 1.5;
    margin-bottom: 16px;
  `,
  '300': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400 !important;
    line-height: 1.5;
    margin-bottom: 24px;
  `,
};

export type TextStyles = { [key in TextSizes]: any };
export const textStyles: TextStyles = {
  '50': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 400 !important;
    line-height: 1.25;
  `,
  '100': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 1.25;
  `,
  '200': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 1.5;
  `,
  '300': css`
    font-family: Averta, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400 !important;
    line-height: 1.2;
  `,
};

export type Theme = {
  space: { [key in Spacing]: string };
  sizes: { [key in Spacing]: string };
  fontWeight: { [key in FontWeight]: string | number };
  textAlign: { [key in TextAlign]: string };
  textColor: { [key in TextColor]: string };
  textDecoration: { [key in TextDecoration]: string };
  textTransform: { [key in TextTransform]: string };
  buttons: Record<string, Record<string, unknown>>;
  variants: Record<string, Record<string, string>>;
  colors: Record<string, Record<string, string | Record<string, string>>>;
  background?: Record<string, string | Record<string, string>>;
  backgroundImage?: { gradient: Record<string, string> };
  shadows: Record<string, string>;
  radii: Record<string, string>;
  breakpoints?: string[];
};

const theme: Theme = {
  colors: {
    border: {
      primary: '#C9CFD4',
      secondary: '#D7DCE0',
      contrast: '#62686E',
      error: '#BC4338',
      caution: '#F8B816',
      success: '#1A824E',
      info: '#C9CFD4',
      highlight: '#116876',
      input_focus: '#2E67D1',
    },
    blue: {
      '100': '#ECF1FB',
      '200': '#C5D5F2',
      '300': '#A3BDEB',
      '400': '#7DA1E2',
      '500': '#5685DA',
      '600': '#2E67D1',
      '700': '#2D5CB1',
      '800': '#24498C',
      '900': '#172F5B',
    },
    cyan: {
      '100': '#E8F9FC',
      '200': '#8BE2EF',
      '300': '#39CDE4',
      '400': '#1CB0C7',
      '500': '#1893A7',
      '600': '#127787',
      '700': '#116876',
      '800': '#0D535D',
      '900': '#09363D',
    },
    green: {
      '100': '#F1FDF6',
      '200': '#BAE8C9',
      '300': '#6BD094',
      '400': '#05B86C',
      '500': '#169A5B',
      '600': '#1A824E',
      '700': '#1B6C42',
      '800': '#1B5535',
      '900': '#173724',
    },
    orange: {
      '100': '#FCF5EF',
      '200': '#FFD4A8',
      '300': '#FFA96E',
      '400': '#FF8A46',
      '500': '#FF7525',
      '600': '#E0621B',
      '700': '#BB4F12',
      '800': '#77320B',
      '900': '#331706',
    },
    pink: {
      '100': '#FFF2F7',
      '200': '#FFD6E8',
      '300': '#FFA6CC',
      '400': '#FF7DB1',
      '500': '#FF66A3',
      '600': '#D13880',
      '700': '#99215F',
      '800': '#330A22',
      '900': '#331706',
    },
    red: {
      '100': '#FFEDE8',
      '200': '#FFC8BC',
      '300': '#FFA494',
      '400': '#FF7665',
      '500': '#E6564A',
      '600': '#BC4338',
      '700': '#A03F37',
      '800': '#7D342D',
      '900': '#4F231F',
    },
    yellow: {
      '100': '#FCF6EB',
      '200': '#FFECBD',
      '300': '#FFD76A',
      '400': '#F8B816',
      '500': '#D59418',
      '600': '#B77F1D',
      '700': '#80561A',
      '800': '#654418',
      '900': '#412C13',
    },
    neutral: {
      '100': '#F4F5F6',
      '200': '#E6E8EB',
      '300': '#D7DCE0',
      '400': '#C9CFD4',
      '500': '#9EA5AC',
      '600': '#767C82',
      '700': '#62686E',
      '800': '#404347',
      '900': '#1F2124',
      white: '#FFFFFF',
      black: '#000000',
    },
    brand: {
      rover: '#00BD70',
      dogbuddy: '#FF5A5B',
      facebook: '#3B5998',
      instagram: '#8A3AB9',
      twitter: '#1DA1F2',
      x: '#000000',
      pinterest: '#C8232C',
      google: '#4285F4',
      apple: '#000000',
    },
    interactive: {
      primary_original: '{color.blue.600.value}',
      primary_pressed_original: '{color.blue.700.value}',
    },
    interactive_border: {
      primary_base: '#C9CFD4',
      primary_pressed: '#767C82',
      primary_active: '#404347',
      primary_active_pressed: '#1F2124',
      disabled_base: '#E6E8EB',
    },
    interactive_text: {
      primary_base: '#404347',
      primary_active: '#2E67D1',
      disabled_base: '#9EA5AC',
      destructive_base: '#BC4338',
      link_primary_base: '#2E67D1',
      link_primary_pressed: '#2D5CB1',
      link_primary_inverse_base: '#FFFFFF',
      link_primary_inverse_pressed: '#F4F5F6',
      link_secondary_base: '#404347',
      link_secondary_pressed: '#1F2124',
      link_secondary_inverse_base: '#F4F5F6',
      link_secondary_inverse_pressed: '#E6E8EB',
    },
    interactive_link: {
      primary_base: '#2E67D1',
      primary_hover: '#24498C',
      primary_visited: '#2E67D1',
      primary_inverse_base: '#A3BDEB',
      primary_inverse_hover: '#ECF1FB',
      primary_inverse_visited: '#C5D5F2',
      secondary_base: '#62686E',
      secondary_hover: '#1F2124',
      secondary_visited: '#A3BDEB',
      secondary_inverse_base: '#F4F5F6',
      secondary_inverse_hover: '#D7DCE0',
      secondary_inverse_visited: '#F4F5F6',
    },
    separator: {
      primary: '#D7DCE0',
    },
    text: {
      primary: '#1F2124',
      primary_inverse: '#FFFFFF',
      secondary: '#404347',
      secondary_inverse: '#F4F5F6',
      tertiary: '#62686E',
      tertiary_inverse: '#E6E8EB',
      placeholder: '#62686E',
      disabled: '#9EA5AC',
      price: '#BB4F12',
      error: '#BC4338',
      caution: '#654418',
      success: '#1B6C42',
    },
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#F4F5F6',
    tertiary: '#E6E8EB',
    contrast: '#404347',
    accent: '#FCF6EB',
    accent_secondary: '#ECF1FB',
    overlay: 'rgba(31, 33, 36, 0.48)',
    overlay_accent: 'rgba(22, 154, 91, 0.24)',
    error: '#FFEDE8',
    caution: '#FCF6EB',
    success: '#F1FDF6',
    info: '#F4F5F6',
    highlight: '#E8F9FC',
    attention_highlight: '#F8B816',
    attention_blue: '#C5D5F2',
    interactive: {
      primary_base: '#FFFFFF',
      primary_pressed: '#F4F5F6',
      primary_active: '#404347',
      primary_active_pressed: '#1F2124',
      button_primary_base: '#2E67D1',
      button_primary_pressed: '#2D5CB1',
      destructive_base: '#BC4338',
      destructive_hover: '#A03F37',
      disabled_base: '#F4F5F6',
    },
    gradient: {},
  },
  space: {
    '-64x': '-256px',
    '-48x': '-192px',
    '-40x': '-160px',
    '-32x': '-128px',
    '-24x': '-96px',
    '-20x': '-80px',
    '-16x': '-64px',
    '-12x': '-48px',
    '-10x': '-40px',
    '-8x': '-32px',
    '-6x': '-24px',
    '-5x': '-20px',
    '-4x': '-16px',
    '-3x': '-12px',
    '-2x': '-8px',
    '-1x': '-4px',
    '0x': '0px',
    '1x': '4px',
    '2x': '8px',
    '3x': '12px',
    '4x': '16px',
    '5x': '20px',
    '6x': '24px',
    '8x': '32px',
    '10x': '40px',
    '12x': '48px',
    '16x': '64px',
    '20x': '80px',
    '24x': '96px',
    '32x': '128px',
    '40x': '160px',
    '48x': '192px',
    '64x': '256px',
  },
  sizes: {
    '-64x': '-256px',
    '-48x': '-192px',
    '-40x': '-160px',
    '-32x': '-128px',
    '-24x': '-96px',
    '-20x': '-80px',
    '-16x': '-64px',
    '-12x': '-48px',
    '-10x': '-40px',
    '-8x': '-32px',
    '-6x': '-24px',
    '-5x': '-20px',
    '-4x': '-16px',
    '-3x': '-12px',
    '-2x': '-8px',
    '-1x': '-4px',
    '0x': '0px',
    '1x': '4px',
    '2x': '8px',
    '3x': '12px',
    '4x': '16px',
    '5x': '20px',
    '6x': '24px',
    '8x': '32px',
    '10x': '40px',
    '12x': '48px',
    '16x': '64px',
    '20x': '80px',
    '24x': '96px',
    '32x': '128px',
    '40x': '160px',
    '48x': '192px',
    '64x': '256px',
  },
  radii: {
    primary: '8px',
    secondary: '4px',
    round: '99999px',
  },
  shadows: {
    elevation_high: '0px 8px 10px -6px rgba(27, 31, 35, 0.22)',
    elevation_mid: '0px 2px 12px -1px rgba(27, 31, 35, 0.24)',
    elevation_low: '0px 1px 4px 0px rgba(27, 31, 35, 0.32)',
    border_primary: '0px 0px 0px 2px rgba(207, 215, 222, 1)',
    border_contrast: '0px 0px 0px 2px rgba(102, 114, 124, 1)',
    border_disabled: '0px 0px 0px 2px rgba(232, 235, 237, 1)',
  },
  fontWeight: {
    regular: '400 !important',
    semibold: '700 !important',
  },
  textColor: {
    primary: '#1F2124',
    primary_inverse: '#FFFFFF',
    secondary: '#404347',
    secondary_inverse: '#F4F5F6',
    tertiary: '#62686E',
    tertiary_inverse: '#E6E8EB',
    placeholder: '#62686E',
    disabled: '#9EA5AC',
    price: '#BB4F12',
    error: '#BC4338',
    caution: '#654418',
    success: '#1B6C42',
  },
  textAlign: {
    left: 'left',
    center: 'center',
    right: 'right',
  },
  textDecoration: {
    none: 'none',
    underline: 'underline',
    'line-through': 'line-through',
  },
  textTransform: {
    none: 'none',
    uppercase: 'uppercase',
  },
  variants: {
    card_default: {
      padding: '5x',
      boxShadow: 'elevation_mid',
      borderRadius: 'primary',
    },
    elevation_low: {
      boxShadow: 'elevation_low',
    },
    elevation_mid: {
      boxShadow: 'elevation_mid',
    },
    elevation_high: {
      boxShadow: 'elevation_high',
    },
  },
  buttons: {
    base: {
      borderRadius: 'round',
      cursor: 'pointer',
      bg: 'transparent',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'antialiased',
      '&:hover,&:focus': {
        textDecoration: 'none',
      },
    },
    disabled: {
      variant: 'buttons.base',
      color: 'text.disabled',
      background: 'interactive.disabled_base',
      boxShadow: 'border_disabled',
      cursor: 'not-allowed',
      '&:hover,&:focus': {
        color: 'text.disabled',
      },
    },
    default: {
      base: {
        variant: 'buttons.base',
        color: 'text.secondary',
        background: 'interactive.primary_base',
        boxShadow: 'border_primary',
        '&:hover,&:focus': {
          color: 'text.secondary',
          background: 'interactive.primary_pressed',
        },
      },
      contrast: {
        variant: 'buttons.base',
        color: 'text.primary_inverse',
        background: 'contrast',
        boxShadow: 'border_contrast',
        '&:hover,&:focus': {
          color: 'text.primary_inverse',
          backgroundColor: 'neutral.900',
        },
      },
      destructive: {
        variant: 'buttons.default.base',
        color: 'text.error',
        '&:hover,&:focus': {
          color: 'text.error',
          background: 'error',
        },
      },
      contrastDestructive: {
        variant: 'buttons.base',
        color: 'red.100',
        boxShadow: 'border_contrast',
        '&:hover,&:focus': {
          color: 'red.400',
          backgroundColor: 'red.900',
        },
      },
    },
    primary: {
      base: {
        variant: 'buttons.base',
        color: 'text.primary_inverse',
        background: 'interactive.button_primary_base',
        boxShadow: 'elevation_mid',
        '&:hover,&:focus': {
          color: 'text.primary_inverse',
          background: 'interactive.button_primary_pressed',
        },
      },
      contrast: {
        variant: 'buttons.base',
        color: 'text.primary_inverse',
        background: 'interactive.button_primary_base',
        boxShadow: 'elevation_mid',
        '&:hover,&:focus': {
          color: 'text.primary_inverse',
          background: 'interactive.button_primary_pressed',
        },
      },
      destructive: {
        variant: 'buttons.base',
        color: 'text.primary_inverse',
        background: 'interactive.destructive_base',
        boxShadow: 'elevation_mid',
        '&:hover,&:focus': {
          color: 'text.primary_inverse',
          background: 'interactive.destructive_hover',
        },
      },
      contrastDestructive: {
        variant: 'buttons.base',
        color: 'text.primary_inverse',
        background: 'interactive.destructive_base',
        boxShadow: 'elevation_mid',
        '&:hover,&:focus': {
          color: 'text.primary_inverse',
          background: 'interactive.destructive_hover',
        },
      },
    },
    flat: {
      base: {
        variant: 'buttons.base',
        color: 'interactive_text.link_primary_base',
        '&:hover,&:focus': {
          color: 'interactive_text.link_primary_pressed',
          background: 'interactive.primary_pressed',
        },
      },
      contrast: {
        variant: 'buttons.base',
        color: 'text.secondary_inverse',
        '&:hover,&:focus': {
          color: 'text.primary_inverse',
          backgroundColor: 'neutral.900',
        },
      },
      destructive: {
        variant: 'buttons.base',
        color: 'text.error',
        '&:hover,&:focus': {
          color: 'red.700',
          backgroundColor: 'red.100',
        },
      },
      contrastDestructive: {
        variant: 'buttons.base',
        color: 'red.400',
        '&:hover,&:focus': {
          color: 'red.300',
          backgroundColor: 'red.900',
        },
      },
    },
  },
};

export default theme;
