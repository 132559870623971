import { system } from 'styled-system';

import type { ThemeFontWeight } from '@rover/kibble/styles';

export type FontWeightProps = {
  fontWeight?: ThemeFontWeight | ThemeFontWeight[];
};

const getFontWeight = (weight: ThemeFontWeight): string => {
  switch (weight) {
    case 'semibold':
      return '700';

    case 'regular':
    default:
      return '400';
  }
};

const config = system({
  fontWeight: {
    property: 'fontWeight',
    scale: 'fontWeight',
    transform: (propValue) => {
      const fontWeight = getFontWeight(propValue);
      // if not on react native, append fallback fonts
      if (!process.env.JS_ENV_NATIVE) {
        return `${fontWeight}`;
      }
      return fontWeight;
    },
  },
});
export default config;
