import React from 'react';
import { ThemeProvider as LibThemeProvider } from 'styled-components';

import type { Theme } from '@rover/kibble/styles';
import { themeOverride } from '@rover/kibble/styles';

export type Props = {
  theme?: Theme;
  children: React.ReactNode;
};

const ThemeProvider = ({ theme = themeOverride, children }: Props): JSX.Element => (
  <LibThemeProvider theme={theme}>{children}</LibThemeProvider>
);

export default ThemeProvider;
