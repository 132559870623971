import { system } from 'styled-system';

import type { ThemeTextTransform } from '@rover/kibble/styles';

import { createTransformWithDefault } from './transforms';

export type TextTransformProps = {
  textTransform?: ThemeTextTransform;
};
const config = system({
  textTransform: {
    property: 'textTransform',
    scale: 'textTransform',
    transform: createTransformWithDefault('none'),
  },
});
export default config;
