import { system } from 'styled-system';

import type { ThemeTextColor } from '@rover/kibble/styles';

import { createTransformWithDefault } from './transforms';

export type TextColorProps = {
  textColor?: ThemeTextColor;
};
const config = system({
  textColor: {
    property: 'color',
    scale: 'textColor',
    transform: createTransformWithDefault(undefined),
  },
});
export default config;
