/**
 * Do not edit directly
 * Generated by i18nFormatMap.js on Mon, 03 Mar 2025 20:21:30 GMT
 */

const I18N_FORMAT_MAP = {
  'en-us': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'MMMM DD, YYYY',
    _DATE_FULL_example: 'October 7, 2019',
    DATE_MED: 'MMM DD, YYYY',
    _DATE_MED_example: 'Oct 7, 2019',
    DATE_SHORT: 'MM/DD/YYYY',
    _DATE_SHORT_example: '10/07/2019',
    DATE_PICKER: 'mm/dd/yy',
    _DATE_PICKER_example: '10/07/19',
    DATE_WEEK_SHORT: 'ddd, MM/DD/YY',
    _DATE_WEEK_SHORT_example: 'Mon, 10/07/19',
    MONTH_DAY_FULL: 'MMMM DD',
    _MONTH_DAY_FULL_example: 'October 7',
    MONTH_DAY_MED: 'MMM D',
    _MONTH_DAY_MED_example: 'Oct 7',
    MONTH_DAY_SHORT: 'MM/DD',
    _MONTH_DAY_SHORT_example: '10/07',
    MONTH_DAY_WEEK_FULL: 'dddd, MMMM DD',
    _MONTH_DAY_WEEK_FULL_example: 'Monday, October 07',
    MONTH_DAY_WEEK_MED: 'ddd, MMM DD',
    _MONTH_DAY_WEEK_MED_example: 'Mon, Oct 07',
    MONTH_DAY_WEEK_SHORT: 'ddd, MM/DD',
    _MONTH_DAY_WEEK_SHORT_example: 'Mon, 10/07',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'October',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'October 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'Oct',
    MONTH_YEAR_MED: 'MMM YYYY',
    _MONTH_YEAR_MED_example: 'Oct 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'h:mm A',
    _TIME_SIMPLE_example: '6:14 PM',
    TIME_PICKER: 'g:i A',
    _TIME_PICKER_example: '6:14 PM',
    TIME_WITH_SECOND: 'h:mm:ss A',
    _TIME_WITH_SECOND_example: '6:14:13 PM',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Monday',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Mon',
  },
  i18n: {
    DAY_SHORT: {
      moment: 'D',
      django: 'd',
      comment: '7 (07 in python)',
    },
    DATE_ISO: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    DATE_FULL: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    },
    DATE_MED: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    DATE_SHORT: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    DATE_PICKER: {},
    DATE_WEEK_SHORT: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      weekday: 'short',
    },
    MONTH_DAY_FULL: {
      month: 'long',
      day: '2-digit',
    },
    MONTH_DAY_MED: {
      month: 'short',
      day: 'numeric',
    },
    MONTH_DAY_SHORT: {
      month: '2-digit',
      day: '2-digit',
    },
    MONTH_DAY_WEEK_FULL: {
      month: 'long',
      day: '2-digit',
      weekday: 'long',
    },
    MONTH_DAY_WEEK_MED: {
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    },
    MONTH_DAY_WEEK_SHORT: {
      month: '2-digit',
      day: '2-digit',
      weekday: 'short',
    },
    MONTH_FULL: {
      month: 'long',
    },
    MONTH_YEAR_FULL: {
      year: 'numeric',
      month: 'long',
    },
    MONTH_MED: {
      month: 'numeric',
    },
    MONTH_YEAR_MED: {
      year: 'numeric',
      month: 'short',
    },
    MONTH_YEAR_SHORT: {
      year: 'numeric',
      month: '2-digit',
    },
    TIME_SIMPLE: {
      hour: '2-digit',
      minute: '2-digit',
    },
    TIME_PICKER: {},
    TIME_WITH_SECOND: {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    TIME_24_SIMPLE: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    TIME_24_WITH_SECOND: {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    },
    WEEKDAY_FULL: {},
    WEEKDAY_MED: {
      weekday: 'short',
    },
  },
  'en-ca': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'MMMM DD, YYYY',
    _DATE_FULL_example: 'October 7, 2019',
    DATE_MED: 'MMM DD, YYYY',
    _DATE_MED_example: 'Oct 7, 2019',
    DATE_SHORT: 'MM/DD/YYYY',
    _DATE_SHORT_example: '10/07/2019',
    DATE_PICKER: 'mm/dd/yy',
    _DATE_PICKER_example: '10/07/19',
    DATE_WEEK_SHORT: 'ddd, MM/DD/YY',
    _DATE_WEEK_SHORT_example: 'Mon, 10/07/19',
    MONTH_DAY_FULL: 'MMMM DD',
    _MONTH_DAY_FULL_example: 'October 7',
    MONTH_DAY_MED: 'MMM D',
    _MONTH_DAY_MED_example: 'Oct 7',
    MONTH_DAY_SHORT: 'MM/DD',
    _MONTH_DAY_SHORT_example: '10/07',
    MONTH_DAY_WEEK_FULL: 'dddd, MMMM DD',
    _MONTH_DAY_WEEK_FULL_example: 'Monday, October 07',
    MONTH_DAY_WEEK_MED: 'ddd, MMM DD',
    _MONTH_DAY_WEEK_MED_example: 'Mon, Oct 07',
    MONTH_DAY_WEEK_SHORT: 'ddd, MM/DD',
    _MONTH_DAY_WEEK_SHORT_example: 'Mon, 10/07',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'October',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'October 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'Oct',
    MONTH_YEAR_MED: 'MMM YYYY',
    _MONTH_YEAR_MED_example: 'Oct 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'h:mm A',
    _TIME_SIMPLE_example: '6:14 PM',
    TIME_PICKER: 'g:i A',
    _TIME_PICKER_example: '6:14 PM',
    TIME_WITH_SECOND: 'h:mm:ss A',
    _TIME_WITH_SECOND_example: '6:14:13 PM',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Monday',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Mon',
  },
  'en-gb': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD MMMM YYYY',
    _DATE_FULL_example: '7 October 2019',
    DATE_MED: 'DD MMM YYYY',
    _DATE_MED_example: '7 Oct 2019',
    DATE_SHORT: 'DD/MM/YYYY',
    _DATE_SHORT_example: '07/10/2019',
    DATE_PICKER: 'dd/mm/yy',
    _DATE_PICKER_example: '07/10/19',
    DATE_WEEK_SHORT: 'ddd, DD/MM/YY',
    _DATE_WEEK_SHORT_example: 'Mon, 07/10/19',
    MONTH_DAY_FULL: 'DD MMMM',
    _MONTH_DAY_FULL_example: '7 October',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 Oct',
    MONTH_DAY_SHORT: 'DD/MM',
    _MONTH_DAY_SHORT_example: '07/10',
    MONTH_DAY_WEEK_FULL: 'dddd DD MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Monday 07 October',
    MONTH_DAY_WEEK_MED: 'ddd DD MMM',
    _MONTH_DAY_WEEK_MED_example: 'Mon 07 Oct',
    MONTH_DAY_WEEK_SHORT: 'ddd DD/MM',
    _MONTH_DAY_WEEK_SHORT_example: 'Mon 07/10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'October',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'October 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'Oct',
    MONTH_YEAR_MED: 'MMM YYYY',
    _MONTH_YEAR_MED_example: 'Oct 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Monday',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Mon',
  },
  'en-ie': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD MMMM YYYY',
    _DATE_FULL_example: '7 October 2019',
    DATE_MED: 'DD MMM YYYY',
    _DATE_MED_example: '7 Oct 2019',
    DATE_SHORT: 'DD/MM/YYYY',
    _DATE_SHORT_example: '07/10/2019',
    DATE_PICKER: 'dd/mm/yy',
    _DATE_PICKER_example: '07/10/19',
    DATE_WEEK_SHORT: 'ddd, DD/MM/YY',
    _DATE_WEEK_SHORT_example: 'Mon, 07/10/19',
    MONTH_DAY_FULL: 'DD MMMM',
    _MONTH_DAY_FULL_example: '7 October',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 Oct',
    MONTH_DAY_SHORT: 'DD/MM',
    _MONTH_DAY_SHORT_example: '07/10',
    MONTH_DAY_WEEK_FULL: 'dddd DD MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Monday 07 October',
    MONTH_DAY_WEEK_MED: 'ddd DD MMM',
    _MONTH_DAY_WEEK_MED_example: 'Mon 07 Oct',
    MONTH_DAY_WEEK_SHORT: 'ddd DD/MM',
    _MONTH_DAY_WEEK_SHORT_example: 'Mon 07/10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'October',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'October 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'Oct',
    MONTH_YEAR_MED: 'MMM YYYY',
    _MONTH_YEAR_MED_example: 'Oct 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Monday',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Mon',
  },
  'fr-fr': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD MMMM YYYY',
    _DATE_FULL_example: '7 octobre 2019',
    DATE_MED: 'DD MMM YYYY',
    _DATE_MED_example: '7 Oct 2019',
    DATE_SHORT: 'DD/MM/YYYY',
    _DATE_SHORT_example: '07/10/2019',
    DATE_PICKER: 'dd/mm/yy',
    _DATE_PICKER_example: '07/10/19',
    DATE_WEEK_SHORT: 'ddd DD/MM/YY',
    _DATE_WEEK_SHORT_example: 'Lun 07/10/19',
    MONTH_DAY_FULL: 'DD MMMM',
    _MONTH_DAY_FULL_example: '7 octobre',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 Oct',
    MONTH_DAY_SHORT: 'DD/MM',
    _MONTH_DAY_SHORT_example: '07/10',
    MONTH_DAY_WEEK_FULL: 'dddd DD MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Lundi 07 octobre',
    MONTH_DAY_WEEK_MED: 'ddd DD MMM',
    _MONTH_DAY_WEEK_MED_example: 'Lun 07 Oct',
    MONTH_DAY_WEEK_SHORT: 'ddd DD/MM',
    _MONTH_DAY_WEEK_SHORT_example: 'Lun 07/10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'octobre',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'octobre 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'oct.',
    MONTH_YEAR_MED: 'MMM YYYY',
    _MONTH_YEAR_MED_example: 'Oct 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Lundi',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Lun',
  },
  'de-de': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD. MMMM YYYY',
    _DATE_FULL_example: '7. Oktober 2019',
    DATE_MED: 'DD. MMM YYYY',
    _DATE_MED_example: '7. Okt. 2019',
    DATE_SHORT: 'DD.MM.YYYY',
    _DATE_SHORT_example: '07.10.2019',
    DATE_PICKER: 'dd.mm.yy',
    _DATE_PICKER_example: '07.10.19',
    DATE_WEEK_SHORT: 'ddd, DD.MM.YY',
    _DATE_WEEK_SHORT_example: 'MO, 07.10.19',
    MONTH_DAY_FULL: 'DD. MMMM',
    _MONTH_DAY_FULL_example: '7. Oktober',
    MONTH_DAY_MED: 'D. MMM',
    _MONTH_DAY_MED_example: '7. Okt.',
    MONTH_DAY_SHORT: 'DD.MM.',
    _MONTH_DAY_SHORT_example: '07.10.',
    MONTH_DAY_WEEK_FULL: 'dddd, DD. MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Montag, 07. Oktober',
    MONTH_DAY_WEEK_MED: 'ddd, DD. MMM',
    _MONTH_DAY_WEEK_MED_example: 'MO, 07. Okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, DD.MM.',
    _MONTH_DAY_WEEK_SHORT_example: 'MO, 07.10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'Oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'Oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'Okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'Oktober 2019',
    MONTH_YEAR_SHORT: 'MM.YYYY',
    _MONTH_YEAR_SHORT_example: '10.2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Montag',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'MO',
  },
  'de-ch': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD. MMMM YYYY',
    _DATE_FULL_example: '7. Oktober 2019',
    DATE_MED: 'DD. MMM YYYY',
    _DATE_MED_example: '7. Okt. 2019',
    DATE_SHORT: 'DD.MM.YYYY',
    _DATE_SHORT_example: '07.10.2019',
    DATE_PICKER: 'dd.mm.yy',
    _DATE_PICKER_example: '07.10.19',
    DATE_WEEK_SHORT: 'ddd, DD.MM.YY',
    _DATE_WEEK_SHORT_example: 'MO, 07.10.19',
    MONTH_DAY_FULL: 'DD. MMMM',
    _MONTH_DAY_FULL_example: '7. Oktober',
    MONTH_DAY_MED: 'D. MMM',
    _MONTH_DAY_MED_example: '7. Okt',
    MONTH_DAY_SHORT: 'DD.MM.',
    _MONTH_DAY_SHORT_example: '07.10.',
    MONTH_DAY_WEEK_FULL: 'dddd, DD. MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Montag, 07. Oktober',
    MONTH_DAY_WEEK_MED: 'ddd, DD. MMM',
    _MONTH_DAY_WEEK_MED_example: 'MO, 07. Okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, DD.MM.',
    _MONTH_DAY_WEEK_SHORT_example: 'MO, 07.10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'Oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'Oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'Okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'Oktober 2019',
    MONTH_YEAR_SHORT: 'MM.YYYY',
    _MONTH_YEAR_SHORT_example: '10.2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Montag',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'MO',
  },
  'de-at': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD. MMMM YYYY',
    _DATE_FULL_example: '7. Oktober 2019',
    DATE_MED: 'DD. MMM YYYY',
    _DATE_MED_example: '7. Okt. 2019',
    DATE_SHORT: 'DD.MM.YYYY',
    _DATE_SHORT_example: '07.10.2019',
    DATE_PICKER: 'dd.mm.yy',
    _DATE_PICKER_example: '07.10.19',
    DATE_WEEK_SHORT: 'ddd, DD.MM.YY',
    _DATE_WEEK_SHORT_example: 'MO, 07.10.19',
    MONTH_DAY_FULL: 'DD. MMMM',
    _MONTH_DAY_FULL_example: '7. Oktober',
    MONTH_DAY_MED: 'D. MMM',
    _MONTH_DAY_MED_example: '7. Okt',
    MONTH_DAY_SHORT: 'DD.MM.',
    _MONTH_DAY_SHORT_example: '07.10.',
    MONTH_DAY_WEEK_FULL: 'dddd, DD. MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Montag, 07. Oktober',
    MONTH_DAY_WEEK_MED: 'ddd, DD. MMM',
    _MONTH_DAY_WEEK_MED_example: 'MO, 07. Okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, DD.MM.',
    _MONTH_DAY_WEEK_SHORT_example: 'MO, 07.10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'Oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'Oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'Okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'Oktober 2019',
    MONTH_YEAR_SHORT: 'MM.YYYY',
    _MONTH_YEAR_SHORT_example: '10.2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Montag',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'MO',
  },
  'es-es': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD [de] MMMM [de] YYYY',
    _DATE_FULL_example: '7 de Octubre de 2019',
    DATE_MED: 'DD [de] MMM [de] YYYY',
    _DATE_MED_example: '7 de oct de 2019',
    DATE_SHORT: 'DD/MM/YYYY',
    _DATE_SHORT_example: '07/10/2019',
    DATE_PICKER: 'dd/mm/yy',
    _DATE_PICKER_example: '7/10/19',
    DATE_WEEK_SHORT: 'ddd, DD/MM/YYYY',
    _DATE_WEEK_SHORT_example: 'Lunes, 7/10/2019',
    MONTH_DAY_FULL: 'DD [de] MMMM',
    _MONTH_DAY_FULL_example: '7 de Octubre',
    MONTH_DAY_MED: 'DD [de] MMM',
    _MONTH_DAY_MED_example: '07 de oct',
    MONTH_DAY_SHORT: 'DD/MM',
    _MONTH_DAY_SHORT_example: '07/10',
    MONTH_DAY_WEEK_FULL: 'dddd, DD [de] MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'lunes, 07 de Octubre',
    MONTH_DAY_WEEK_MED: 'dddd, DD [de] MMM',
    _MONTH_DAY_WEEK_MED_example: 'lunes, 07 de oct',
    MONTH_DAY_WEEK_SHORT: 'dddd, DD/MM',
    _MONTH_DAY_WEEK_SHORT_example: 'lunes, 07/10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'Octubre',
    MONTH_YEAR_FULL: 'MMMM [de] YYYY',
    _MONTH_YEAR_FULL_example: 'Octubre de 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'oct.',
    MONTH_YEAR_MED: 'MMMM [de] YYYY',
    _MONTH_YEAR_MED_example: 'Octubre de 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'lunes',
    WEEKDAY_MED: 'dddd',
    _WEEKDAY_MED_example: 'lunes',
  },
  'it-it': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD MMMM YYYY',
    _DATE_FULL_example: '7 Ottobre 2019',
    DATE_MED: 'DD MMM YYYY',
    _DATE_MED_example: '7 Ott 2019',
    DATE_SHORT: 'DD/MM/YYYY',
    _DATE_SHORT_example: '07/10/2019',
    DATE_PICKER: 'dd/mm/yy',
    _DATE_PICKER_example: '07/10/19',
    DATE_WEEK_SHORT: 'ddd DD/MM/YY',
    _DATE_WEEK_SHORT_example: 'Lun 07/10/2019',
    MONTH_DAY_FULL: 'DD MMMM',
    _MONTH_DAY_FULL_example: '7 Ottobre',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 Ott',
    MONTH_DAY_SHORT: 'DD/MM',
    _MONTH_DAY_SHORT_example: '07/10',
    MONTH_DAY_WEEK_FULL: 'dddd DD MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Lunedì 07 Ottobre',
    MONTH_DAY_WEEK_MED: 'ddd DD MMM',
    _MONTH_DAY_WEEK_MED_example: 'Lun 07 Ott',
    MONTH_DAY_WEEK_SHORT: 'ddd DD/MM',
    _MONTH_DAY_WEEK_SHORT_example: 'Lun 07/10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'Ottobre',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'Ottobre 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'Ott',
    MONTH_YEAR_MED: 'MMM YYYY',
    _MONTH_YEAR_MED_example: 'Ott 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Lunedi',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Lun',
  },
  'da-dk': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD. MMMM YYYY',
    _DATE_FULL_example: '07. oktober 2019',
    DATE_MED: 'DD. MMM YYYY',
    _DATE_MED_example: '07. okt. 2019',
    DATE_SHORT: 'DD.MM.YYYY',
    _DATE_SHORT_example: '07.10.2019',
    DATE_PICKER: 'dd.mm.yy',
    _DATE_PICKER_example: '07.10.19',
    DATE_WEEK_SHORT: 'ddd, DD.MM.YY',
    _DATE_WEEK_SHORT_example: 'Man, 07.10.2019',
    MONTH_DAY_FULL: 'DD. MMMM',
    _MONTH_DAY_FULL_example: '07. oktober',
    MONTH_DAY_MED: 'D. MMM',
    _MONTH_DAY_MED_example: '7. okt.',
    MONTH_DAY_SHORT: 'DD.MM.',
    _MONTH_DAY_SHORT_example: '07.10.',
    MONTH_DAY_WEEK_FULL: 'dddd, DD. MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Mandag, 07. oktober',
    MONTH_DAY_WEEK_MED: 'ddd, DD. MMM',
    _MONTH_DAY_WEEK_MED_example: 'Man, 07 okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, DD.MM.',
    _MONTH_DAY_WEEK_SHORT_example: 'Man, 07.10.',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'oktober 2019',
    MONTH_YEAR_SHORT: 'MM.YYYY',
    _MONTH_YEAR_SHORT_example: '10.2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Mandag',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Man',
  },
  'nb-no': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD. MMMM YYYY',
    _DATE_FULL_example: '7. oktober 2019',
    DATE_MED: 'DD. MMM YYYY',
    _DATE_MED_example: '7. okt. 2019',
    DATE_SHORT: 'DD.MM.YYYY',
    _DATE_SHORT_example: '07.10.2019',
    DATE_PICKER: 'dd.mm.yy',
    _DATE_PICKER_example: '07.10.19',
    DATE_WEEK_SHORT: 'ddd, DD.MM.YY',
    _DATE_WEEK_SHORT_example: 'Man, 07.10.2019',
    MONTH_DAY_FULL: 'DD. MMMM',
    _MONTH_DAY_FULL_example: '7. oktober',
    MONTH_DAY_MED: 'D. MMM',
    _MONTH_DAY_MED_example: '7. okt.',
    MONTH_DAY_SHORT: 'DD.MM.',
    _MONTH_DAY_SHORT_example: '07.10.',
    MONTH_DAY_WEEK_FULL: 'dddd, DD. MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Mandag, 07. oktober',
    MONTH_DAY_WEEK_MED: 'ddd, DD. MMM',
    _MONTH_DAY_WEEK_MED_example: 'Man, 07 okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, DD.MM.',
    _MONTH_DAY_WEEK_SHORT_example: 'Man, 07.10.',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'oktober 2019',
    MONTH_YEAR_SHORT: 'MM.YYYY',
    _MONTH_YEAR_SHORT_example: '10.2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Mandag',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Man',
  },
  'nl-nl': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'D MMMM YYYY',
    _DATE_FULL_example: '7 oktober 2019',
    DATE_MED: 'D MMM YYYY',
    _DATE_MED_example: '7 okt. 2019',
    DATE_SHORT: 'D-M-YYYY',
    _DATE_SHORT_example: '7-10-2019',
    DATE_PICKER: 'd-m-yy',
    _DATE_PICKER_example: '7-10-19',
    DATE_WEEK_SHORT: 'ddd, D MMMM YYYY',
    _DATE_WEEK_SHORT_example: 'Ma, 7 oktober 2019',
    MONTH_DAY_FULL: 'D MMMM',
    _MONTH_DAY_FULL_example: '7 oktober',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 okt.',
    MONTH_DAY_SHORT: 'D-M',
    _MONTH_DAY_SHORT_example: '7-10',
    MONTH_DAY_WEEK_FULL: 'dddd, D MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Maandag, 7 oktober',
    MONTH_DAY_WEEK_MED: 'ddd, D MMM',
    _MONTH_DAY_WEEK_MED_example: 'Ma, 7 okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, D-M',
    _MONTH_DAY_WEEK_SHORT_example: 'Ma, 7-10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'oktober 2019',
    MONTH_YEAR_SHORT: 'M-YYYY',
    _MONTH_YEAR_SHORT_example: '10-2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Maandag',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Ma',
  },
  'nl-be': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'D MMMM YYYY',
    _DATE_FULL_example: '7 oktober 2019',
    DATE_MED: 'D MMM YYYY',
    _DATE_MED_example: '7 okt 2019',
    DATE_SHORT: 'D-M-YYYY',
    _DATE_SHORT_example: '7-10-2019',
    DATE_PICKER: 'd-m-yy',
    _DATE_PICKER_example: '7-10-19',
    DATE_WEEK_SHORT: 'ddd, D MMMM YYYY',
    _DATE_WEEK_SHORT_example: 'Ma, 7 oktober 2019',
    MONTH_DAY_FULL: 'D MMMM',
    _MONTH_DAY_FULL_example: '7 oktober',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 okt',
    MONTH_DAY_SHORT: 'D-M',
    _MONTH_DAY_SHORT_example: '7-10',
    MONTH_DAY_WEEK_FULL: 'dddd, D MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Maandag, 7 oktober',
    MONTH_DAY_WEEK_MED: 'ddd, D MMM',
    _MONTH_DAY_WEEK_MED_example: 'Ma, 7 okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, D-M',
    _MONTH_DAY_WEEK_SHORT_example: 'Ma, 7-10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'oktober 2019',
    MONTH_YEAR_SHORT: 'M-YYYY',
    _MONTH_YEAR_SHORT_example: '10-2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Maandag',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Ma',
  },
  'fi-fi': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'D MMMM YYYY',
    _DATE_FULL_example: '7 oktober 2019',
    DATE_MED: 'D MMM YYYY',
    _DATE_MED_example: '7 okt 2019',
    DATE_SHORT: 'D-M-YYYY',
    _DATE_SHORT_example: '7-10-2019',
    DATE_PICKER: 'd-m-yy',
    _DATE_PICKER_example: '7-10-19',
    DATE_WEEK_SHORT: 'ddd, D MMMM YYYY',
    _DATE_WEEK_SHORT_example: 'Ma, 7 oktober 2019',
    MONTH_DAY_FULL: 'D MMMM',
    _MONTH_DAY_FULL_example: '7 oktober',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 okt',
    MONTH_DAY_SHORT: 'D-M',
    _MONTH_DAY_SHORT_example: '7-10',
    MONTH_DAY_WEEK_FULL: 'dddd, D MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Maandag, 7 oktober',
    MONTH_DAY_WEEK_MED: 'ddd, D MMM',
    _MONTH_DAY_WEEK_MED_example: 'Ma, 7 okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, D-M',
    _MONTH_DAY_WEEK_SHORT_example: 'Ma, 7-10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'oktober 2019',
    MONTH_YEAR_SHORT: 'M-YYYY',
    _MONTH_YEAR_SHORT_example: '10-2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Maanantai',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Ma',
  },
  'pl-pl': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'D MMMM YYYY',
    _DATE_FULL_example: '7 oktober 2019',
    DATE_MED: 'D MMM YYYY',
    _DATE_MED_example: '7 okt 2019',
    DATE_SHORT: 'D-M-YYYY',
    _DATE_SHORT_example: '7-10-2019',
    DATE_PICKER: 'd-m-yy',
    _DATE_PICKER_example: '7-10-19',
    DATE_WEEK_SHORT: 'ddd, D MMMM YYYY',
    _DATE_WEEK_SHORT_example: 'Ma, 7 oktober 2019',
    MONTH_DAY_FULL: 'D MMMM',
    _MONTH_DAY_FULL_example: '7 oktober',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 okt',
    MONTH_DAY_SHORT: 'D-M',
    _MONTH_DAY_SHORT_example: '7-10',
    MONTH_DAY_WEEK_FULL: 'dddd, D MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Maandag, 7 oktober',
    MONTH_DAY_WEEK_MED: 'ddd, D MMM',
    _MONTH_DAY_WEEK_MED_example: 'Ma, 7 okt.',
    MONTH_DAY_WEEK_SHORT: 'ddd, D-M',
    _MONTH_DAY_WEEK_SHORT_example: 'Ma, 7-10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'okt.',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'oktober 2019',
    MONTH_YEAR_SHORT: 'M-YYYY',
    _MONTH_YEAR_SHORT_example: '10-2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Poniedziałek',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Pn',
  },
  'sv-se': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'D MMMM YYYY',
    _DATE_FULL_example: '7 oktober 2019',
    DATE_MED: 'D MMM YYYY',
    _DATE_MED_example: '7 okt 2019',
    DATE_SHORT: 'YYYY-MM-DD',
    _DATE_SHORT_example: '2019-10-07',
    DATE_PICKER: 'yy-mm-dd',
    _DATE_PICKER_example: '19-10-07',
    DATE_WEEK_SHORT: 'ddd, YYYY-MM-DD',
    _DATE_WEEK_SHORT_example: 'mån, 2019-10-07',
    MONTH_DAY_FULL: 'D MMMM',
    _MONTH_DAY_FULL_example: '7 oktober',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 okt',
    MONTH_DAY_SHORT: 'D/M',
    _MONTH_DAY_SHORT_example: '7/10',
    MONTH_DAY_WEEK_FULL: 'dddd, D MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'måndag, 7 oktober',
    MONTH_DAY_WEEK_MED: 'ddd, D MMM',
    _MONTH_DAY_WEEK_MED_example: 'mån, 7 okt',
    MONTH_DAY_WEEK_SHORT: 'ddd, D/M',
    _MONTH_DAY_WEEK_SHORT_example: 'mån, 7/10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'oktober',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'oktober 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'okt',
    MONTH_YEAR_MED: 'MMMM YYYY',
    _MONTH_YEAR_MED_example: 'oktober 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'måndag',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'mån',
  },
  'fr-ca': {
    DAY_SHORT: 'D',
    _DAY_SHORT_example: '7 (07 in python)',
    DATE_ISO: 'YYYY-MM-DD',
    _DATE_ISO_example: '2019-10-07',
    DATE_FULL: 'DD MMMM YYYY',
    _DATE_FULL_example: '7 octobre 2019',
    DATE_MED: 'DD MMM YYYY',
    _DATE_MED_example: '7 Oct 2019',
    DATE_SHORT: 'DD/MM/YYYY',
    _DATE_SHORT_example: '07/10/2019',
    DATE_PICKER: 'dd/mm/yy',
    _DATE_PICKER_example: '07/10/19',
    DATE_WEEK_SHORT: 'ddd DD/MM/YY',
    _DATE_WEEK_SHORT_example: 'Lun 07/10/19',
    MONTH_DAY_FULL: 'DD MMMM',
    _MONTH_DAY_FULL_example: '7 octobre',
    MONTH_DAY_MED: 'D MMM',
    _MONTH_DAY_MED_example: '7 Oct',
    MONTH_DAY_SHORT: 'DD/MM',
    _MONTH_DAY_SHORT_example: '07/10',
    MONTH_DAY_WEEK_FULL: 'dddd DD MMMM',
    _MONTH_DAY_WEEK_FULL_example: 'Lundi 07 octobre',
    MONTH_DAY_WEEK_MED: 'ddd DD MMM',
    _MONTH_DAY_WEEK_MED_example: 'Lun 07 Oct',
    MONTH_DAY_WEEK_SHORT: 'ddd DD/MM',
    _MONTH_DAY_WEEK_SHORT_example: 'Lun 07/10',
    MONTH_FULL: 'MMMM',
    _MONTH_FULL_example: 'octobre',
    MONTH_YEAR_FULL: 'MMMM YYYY',
    _MONTH_YEAR_FULL_example: 'octobre 2019',
    MONTH_MED: 'MMM',
    _MONTH_MED_example: 'oct.',
    MONTH_YEAR_MED: 'MMM YYYY',
    _MONTH_YEAR_MED_example: 'Oct 2019',
    MONTH_YEAR_SHORT: 'MM/YYYY',
    _MONTH_YEAR_SHORT_example: '10/2019',
    TIME_SIMPLE: 'HH:mm',
    _TIME_SIMPLE_example: '18:14',
    TIME_PICKER: 'H:i',
    _TIME_PICKER_example: '18:14',
    TIME_WITH_SECOND: 'HH:mm:ss',
    _TIME_WITH_SECOND_example: '18:14:13',
    TIME_24_SIMPLE: 'HH:mm',
    _TIME_24_SIMPLE_example: '18:14',
    TIME_24_WITH_SECOND: 'HH:mm:ss',
    _TIME_24_WITH_SECOND_example: '18:14:13',
    WEEKDAY_FULL: 'dddd',
    _WEEKDAY_FULL_example: 'Lundi',
    WEEKDAY_MED: 'ddd',
    _WEEKDAY_MED_example: 'Lun',
  },
};
export default I18N_FORMAT_MAP;
