import DSTheme from '@rover/kibble/tokens/build/es6/theme';

// Web has no overrides, but React Native does, so keeping this file
// preserves the current setup where ThemeProvider works on both platforms

// docs say this odd approach is how to get breakpoint aliases: https://styled-system.com/responsive-styles#using-objects
const breakpoints = ['768px', '992px', '1200px'];
// @ts-expect-error telling TS its ok to add these key aliases is tricky
[breakpoints.sm, breakpoints.md, breakpoints.lg] = breakpoints;

const themeOverride = {
  ...DSTheme,
  breakpoints,
};

export default themeOverride;
