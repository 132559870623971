import { system } from 'styled-system';

import type { ThemeTextDecoration } from '@rover/kibble/styles';

import { createTransformWithDefault } from './transforms';

const property = process.env.JS_ENV_NATIVE ? 'textDecorationLine' : 'textDecoration';

export type TextDecorationProps = {
  textDecoration?: ThemeTextDecoration;
};
const config = system({
  textDecoration: {
    property,
    scale: 'textDecoration',
    transform: createTransformWithDefault('none'),
  },
});
export default config;
