/**
 * Do not edit directly
 * Generated on Mon, 03 Mar 2025 20:21:30 GMT
 */

import * as DSToken from './DSToken';

const DSTokenMap = {
  BACKGROUND_COLOR_PRIMARY: DSToken.BACKGROUND_COLOR_PRIMARY,
  BACKGROUND_COLOR_SECONDARY: DSToken.BACKGROUND_COLOR_SECONDARY,
  BACKGROUND_COLOR_TERTIARY: DSToken.BACKGROUND_COLOR_TERTIARY,
  BACKGROUND_COLOR_CONTRAST: DSToken.BACKGROUND_COLOR_CONTRAST,
  BACKGROUND_COLOR_ACCENT: DSToken.BACKGROUND_COLOR_ACCENT,
  BACKGROUND_COLOR_ACCENT_SECONDARY: DSToken.BACKGROUND_COLOR_ACCENT_SECONDARY,
  BACKGROUND_COLOR_OVERLAY: DSToken.BACKGROUND_COLOR_OVERLAY,
  BACKGROUND_COLOR_OVERLAY_ACCENT: DSToken.BACKGROUND_COLOR_OVERLAY_ACCENT,
  BACKGROUND_COLOR_ERROR: DSToken.BACKGROUND_COLOR_ERROR,
  BACKGROUND_COLOR_CAUTION: DSToken.BACKGROUND_COLOR_CAUTION,
  BACKGROUND_COLOR_SUCCESS: DSToken.BACKGROUND_COLOR_SUCCESS,
  BACKGROUND_COLOR_INFO: DSToken.BACKGROUND_COLOR_INFO,
  BACKGROUND_COLOR_HIGHLIGHT: DSToken.BACKGROUND_COLOR_HIGHLIGHT,
  BACKGROUND_COLOR_ATTENTION_HIGHLIGHT: DSToken.BACKGROUND_COLOR_ATTENTION_HIGHLIGHT,
  BACKGROUND_COLOR_ATTENTION_BLUE: DSToken.BACKGROUND_COLOR_ATTENTION_BLUE,
  BORDER_COLOR_PRIMARY: DSToken.BORDER_COLOR_PRIMARY,
  BORDER_COLOR_SECONDARY: DSToken.BORDER_COLOR_SECONDARY,
  BORDER_COLOR_CONTRAST: DSToken.BORDER_COLOR_CONTRAST,
  BORDER_COLOR_ERROR: DSToken.BORDER_COLOR_ERROR,
  BORDER_COLOR_CAUTION: DSToken.BORDER_COLOR_CAUTION,
  BORDER_COLOR_SUCCESS: DSToken.BORDER_COLOR_SUCCESS,
  BORDER_COLOR_INFO: DSToken.BORDER_COLOR_INFO,
  BORDER_COLOR_HIGHLIGHT: DSToken.BORDER_COLOR_HIGHLIGHT,
  BORDER_COLOR_INPUT_FOCUS: DSToken.BORDER_COLOR_INPUT_FOCUS,
  INTERACTIVE_COLOR_PRIMARY: DSToken.INTERACTIVE_COLOR_PRIMARY,
  INTERACTIVE_COLOR_PRIMARY_PRESSED: DSToken.INTERACTIVE_COLOR_PRIMARY_PRESSED,
  INTERACTIVE_BACKGROUND_COLOR_PRIMARY: DSToken.INTERACTIVE_BACKGROUND_COLOR_PRIMARY,
  INTERACTIVE_BACKGROUND_COLOR_PRIMARY_PRESSED:
    DSToken.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_PRESSED,
  INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE: DSToken.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE,
  INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE_PRESSED:
    DSToken.INTERACTIVE_BACKGROUND_COLOR_PRIMARY_ACTIVE_PRESSED,
  INTERACTIVE_BACKGROUND_COLOR_BUTTON_PRIMARY: DSToken.INTERACTIVE_BACKGROUND_COLOR_BUTTON_PRIMARY,
  INTERACTIVE_BACKGROUND_COLOR_BUTTON_PRIMARY_PRESSED:
    DSToken.INTERACTIVE_BACKGROUND_COLOR_BUTTON_PRIMARY_PRESSED,
  INTERACTIVE_BACKGROUND_COLOR_DESTRUCTIVE: DSToken.INTERACTIVE_BACKGROUND_COLOR_DESTRUCTIVE,
  INTERACTIVE_BACKGROUND_COLOR_DESTRUCTIVE_HOVER:
    DSToken.INTERACTIVE_BACKGROUND_COLOR_DESTRUCTIVE_HOVER,
  INTERACTIVE_BACKGROUND_COLOR_DISABLED: DSToken.INTERACTIVE_BACKGROUND_COLOR_DISABLED,
  INTERACTIVE_BORDER_COLOR_PRIMARY: DSToken.INTERACTIVE_BORDER_COLOR_PRIMARY,
  INTERACTIVE_BORDER_COLOR_PRIMARY_PRESSED: DSToken.INTERACTIVE_BORDER_COLOR_PRIMARY_PRESSED,
  INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE: DSToken.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE,
  INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE_PRESSED:
    DSToken.INTERACTIVE_BORDER_COLOR_PRIMARY_ACTIVE_PRESSED,
  INTERACTIVE_BORDER_COLOR_DISABLED: DSToken.INTERACTIVE_BORDER_COLOR_DISABLED,
  INTERACTIVE_TEXT_COLOR_PRIMARY: DSToken.INTERACTIVE_TEXT_COLOR_PRIMARY,
  INTERACTIVE_TEXT_COLOR_PRIMARY_ACTIVE: DSToken.INTERACTIVE_TEXT_COLOR_PRIMARY_ACTIVE,
  INTERACTIVE_TEXT_COLOR_DISABLED: DSToken.INTERACTIVE_TEXT_COLOR_DISABLED,
  INTERACTIVE_TEXT_COLOR_DESTRUCTIVE: DSToken.INTERACTIVE_TEXT_COLOR_DESTRUCTIVE,
  INTERACTIVE_TEXT_COLOR_LINK_PRIMARY: DSToken.INTERACTIVE_TEXT_COLOR_LINK_PRIMARY,
  INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_PRESSED: DSToken.INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_PRESSED,
  INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_INVERSE: DSToken.INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_INVERSE,
  INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_INVERSE_PRESSED:
    DSToken.INTERACTIVE_TEXT_COLOR_LINK_PRIMARY_INVERSE_PRESSED,
  INTERACTIVE_TEXT_COLOR_LINK_SECONDARY: DSToken.INTERACTIVE_TEXT_COLOR_LINK_SECONDARY,
  INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_PRESSED:
    DSToken.INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_PRESSED,
  INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_INVERSE:
    DSToken.INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_INVERSE,
  INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_INVERSE_PRESSED:
    DSToken.INTERACTIVE_TEXT_COLOR_LINK_SECONDARY_INVERSE_PRESSED,
  INTERACTIVE_LINK_COLOR_PRIMARY: DSToken.INTERACTIVE_LINK_COLOR_PRIMARY,
  INTERACTIVE_LINK_COLOR_PRIMARY_HOVER: DSToken.INTERACTIVE_LINK_COLOR_PRIMARY_HOVER,
  INTERACTIVE_LINK_COLOR_PRIMARY_VISITED: DSToken.INTERACTIVE_LINK_COLOR_PRIMARY_VISITED,
  INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE: DSToken.INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE,
  INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE_HOVER:
    DSToken.INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE_HOVER,
  INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE_VISITED:
    DSToken.INTERACTIVE_LINK_COLOR_PRIMARY_INVERSE_VISITED,
  INTERACTIVE_LINK_COLOR_SECONDARY: DSToken.INTERACTIVE_LINK_COLOR_SECONDARY,
  INTERACTIVE_LINK_COLOR_SECONDARY_HOVER: DSToken.INTERACTIVE_LINK_COLOR_SECONDARY_HOVER,
  INTERACTIVE_LINK_COLOR_SECONDARY_VISITED: DSToken.INTERACTIVE_LINK_COLOR_SECONDARY_VISITED,
  INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE: DSToken.INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE,
  INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE_HOVER:
    DSToken.INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE_HOVER,
  INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE_VISITED:
    DSToken.INTERACTIVE_LINK_COLOR_SECONDARY_INVERSE_VISITED,
  SEPARATOR_COLOR_PRIMARY: DSToken.SEPARATOR_COLOR_PRIMARY,
  TEMPORARY_COLOR_BUTTON_TERTIARY_BACKGROUND_PRESSED:
    DSToken.TEMPORARY_COLOR_BUTTON_TERTIARY_BACKGROUND_PRESSED,
  TEMPORARY_COLOR_BUTTON_DESTRUCTIVE_BACKGROUND_PRESSED:
    DSToken.TEMPORARY_COLOR_BUTTON_DESTRUCTIVE_BACKGROUND_PRESSED,
  TEMPORARY_COLOR_BUTTON_PRIMARY_DISABLED_BACKGROUND:
    DSToken.TEMPORARY_COLOR_BUTTON_PRIMARY_DISABLED_BACKGROUND,
  TEXT_COLOR_PRIMARY: DSToken.TEXT_COLOR_PRIMARY,
  TEXT_COLOR_PRIMARY_INVERSE: DSToken.TEXT_COLOR_PRIMARY_INVERSE,
  TEXT_COLOR_SECONDARY: DSToken.TEXT_COLOR_SECONDARY,
  TEXT_COLOR_SECONDARY_INVERSE: DSToken.TEXT_COLOR_SECONDARY_INVERSE,
  TEXT_COLOR_TERTIARY: DSToken.TEXT_COLOR_TERTIARY,
  TEXT_COLOR_TERTIARY_INVERSE: DSToken.TEXT_COLOR_TERTIARY_INVERSE,
  TEXT_COLOR_PLACEHOLDER: DSToken.TEXT_COLOR_PLACEHOLDER,
  TEXT_COLOR_DISABLED: DSToken.TEXT_COLOR_DISABLED,
  TEXT_COLOR_PRICE: DSToken.TEXT_COLOR_PRICE,
  TEXT_COLOR_ERROR: DSToken.TEXT_COLOR_ERROR,
  TEXT_COLOR_CAUTION: DSToken.TEXT_COLOR_CAUTION,
  TEXT_COLOR_SUCCESS: DSToken.TEXT_COLOR_SUCCESS,
  BORDER_RADIUS_PRIMARY: DSToken.BORDER_RADIUS_PRIMARY,
  BORDER_RADIUS_SECONDARY: DSToken.BORDER_RADIUS_SECONDARY,
  BORDER_RADIUS_ROUND: DSToken.BORDER_RADIUS_ROUND,
  BORDER_WIDTH_PRIMARY: DSToken.BORDER_WIDTH_PRIMARY,
  BORDER_WIDTH_SECONDARY: DSToken.BORDER_WIDTH_SECONDARY,
  SPACE_NEGATIVE_64X: DSToken.SPACE_NEGATIVE_64X,
  SPACE_NEGATIVE_48X: DSToken.SPACE_NEGATIVE_48X,
  SPACE_NEGATIVE_40X: DSToken.SPACE_NEGATIVE_40X,
  SPACE_NEGATIVE_32X: DSToken.SPACE_NEGATIVE_32X,
  SPACE_NEGATIVE_24X: DSToken.SPACE_NEGATIVE_24X,
  SPACE_NEGATIVE_20X: DSToken.SPACE_NEGATIVE_20X,
  SPACE_NEGATIVE_16X: DSToken.SPACE_NEGATIVE_16X,
  SPACE_NEGATIVE_12X: DSToken.SPACE_NEGATIVE_12X,
  SPACE_NEGATIVE_10X: DSToken.SPACE_NEGATIVE_10X,
  SPACE_NEGATIVE_8X: DSToken.SPACE_NEGATIVE_8X,
  SPACE_NEGATIVE_6X: DSToken.SPACE_NEGATIVE_6X,
  SPACE_NEGATIVE_5X: DSToken.SPACE_NEGATIVE_5X,
  SPACE_NEGATIVE_4X: DSToken.SPACE_NEGATIVE_4X,
  SPACE_NEGATIVE_3X: DSToken.SPACE_NEGATIVE_3X,
  SPACE_NEGATIVE_2X: DSToken.SPACE_NEGATIVE_2X,
  SPACE_NEGATIVE_1X: DSToken.SPACE_NEGATIVE_1X,
  SPACE_0X: DSToken.SPACE_0X,
  SPACE_1X: DSToken.SPACE_1X,
  SPACE_2X: DSToken.SPACE_2X,
  SPACE_3X: DSToken.SPACE_3X,
  SPACE_4X: DSToken.SPACE_4X,
  SPACE_5X: DSToken.SPACE_5X,
  SPACE_6X: DSToken.SPACE_6X,
  SPACE_8X: DSToken.SPACE_8X,
  SPACE_10X: DSToken.SPACE_10X,
  SPACE_12X: DSToken.SPACE_12X,
  SPACE_16X: DSToken.SPACE_16X,
  SPACE_20X: DSToken.SPACE_20X,
  SPACE_24X: DSToken.SPACE_24X,
  SPACE_32X: DSToken.SPACE_32X,
  SPACE_40X: DSToken.SPACE_40X,
  SPACE_48X: DSToken.SPACE_48X,
  SPACE_64X: DSToken.SPACE_64X,
  DISPLAY_400_FONT_FAMILY: DSToken.DISPLAY_400_FONT_FAMILY,
  DISPLAY_400_FONT_SIZE: DSToken.DISPLAY_400_FONT_SIZE,
  DISPLAY_400_FONT_WEIGHT: DSToken.DISPLAY_400_FONT_WEIGHT,
  DISPLAY_400_LINE_HEIGHT: DSToken.DISPLAY_400_LINE_HEIGHT,
  DISPLAY_400_MARGIN_BOTTOM: DSToken.DISPLAY_400_MARGIN_BOTTOM,
  DISPLAY_500_FONT_FAMILY: DSToken.DISPLAY_500_FONT_FAMILY,
  DISPLAY_500_FONT_SIZE: DSToken.DISPLAY_500_FONT_SIZE,
  DISPLAY_500_FONT_WEIGHT: DSToken.DISPLAY_500_FONT_WEIGHT,
  DISPLAY_500_LINE_HEIGHT: DSToken.DISPLAY_500_LINE_HEIGHT,
  DISPLAY_500_MARGIN_BOTTOM: DSToken.DISPLAY_500_MARGIN_BOTTOM,
  DISPLAY_600_FONT_FAMILY: DSToken.DISPLAY_600_FONT_FAMILY,
  DISPLAY_600_FONT_SIZE: DSToken.DISPLAY_600_FONT_SIZE,
  DISPLAY_600_FONT_WEIGHT: DSToken.DISPLAY_600_FONT_WEIGHT,
  DISPLAY_600_LINE_HEIGHT: DSToken.DISPLAY_600_LINE_HEIGHT,
  DISPLAY_600_MARGIN_BOTTOM: DSToken.DISPLAY_600_MARGIN_BOTTOM,
  DISPLAY_RESPONSIVE_400_FONT_SIZE: DSToken.DISPLAY_RESPONSIVE_400_FONT_SIZE,
  DISPLAY_RESPONSIVE_500_FONT_SIZE: DSToken.DISPLAY_RESPONSIVE_500_FONT_SIZE,
  DISPLAY_RESPONSIVE_600_FONT_SIZE: DSToken.DISPLAY_RESPONSIVE_600_FONT_SIZE,
  HEADING_50_FONT_FAMILY: DSToken.HEADING_50_FONT_FAMILY,
  HEADING_50_FONT_SIZE: DSToken.HEADING_50_FONT_SIZE,
  HEADING_50_FONT_WEIGHT: DSToken.HEADING_50_FONT_WEIGHT,
  HEADING_50_LINE_HEIGHT: DSToken.HEADING_50_LINE_HEIGHT,
  HEADING_50_MARGIN_BOTTOM: DSToken.HEADING_50_MARGIN_BOTTOM,
  HEADING_100_FONT_FAMILY: DSToken.HEADING_100_FONT_FAMILY,
  HEADING_100_FONT_SIZE: DSToken.HEADING_100_FONT_SIZE,
  HEADING_100_FONT_WEIGHT: DSToken.HEADING_100_FONT_WEIGHT,
  HEADING_100_LINE_HEIGHT: DSToken.HEADING_100_LINE_HEIGHT,
  HEADING_100_MARGIN_BOTTOM: DSToken.HEADING_100_MARGIN_BOTTOM,
  HEADING_200_FONT_FAMILY: DSToken.HEADING_200_FONT_FAMILY,
  HEADING_200_FONT_SIZE: DSToken.HEADING_200_FONT_SIZE,
  HEADING_200_FONT_WEIGHT: DSToken.HEADING_200_FONT_WEIGHT,
  HEADING_200_LINE_HEIGHT: DSToken.HEADING_200_LINE_HEIGHT,
  HEADING_200_MARGIN_BOTTOM: DSToken.HEADING_200_MARGIN_BOTTOM,
  HEADING_300_FONT_FAMILY: DSToken.HEADING_300_FONT_FAMILY,
  HEADING_300_FONT_SIZE: DSToken.HEADING_300_FONT_SIZE,
  HEADING_300_FONT_WEIGHT: DSToken.HEADING_300_FONT_WEIGHT,
  HEADING_300_LINE_HEIGHT: DSToken.HEADING_300_LINE_HEIGHT,
  HEADING_300_MARGIN_BOTTOM: DSToken.HEADING_300_MARGIN_BOTTOM,
  HEADING_400_FONT_FAMILY: DSToken.HEADING_400_FONT_FAMILY,
  HEADING_400_FONT_SIZE: DSToken.HEADING_400_FONT_SIZE,
  HEADING_400_FONT_WEIGHT: DSToken.HEADING_400_FONT_WEIGHT,
  HEADING_400_LINE_HEIGHT: DSToken.HEADING_400_LINE_HEIGHT,
  HEADING_400_MARGIN_BOTTOM: DSToken.HEADING_400_MARGIN_BOTTOM,
  HEADING_500_FONT_FAMILY: DSToken.HEADING_500_FONT_FAMILY,
  HEADING_500_FONT_SIZE: DSToken.HEADING_500_FONT_SIZE,
  HEADING_500_FONT_WEIGHT: DSToken.HEADING_500_FONT_WEIGHT,
  HEADING_500_LINE_HEIGHT: DSToken.HEADING_500_LINE_HEIGHT,
  HEADING_500_MARGIN_BOTTOM: DSToken.HEADING_500_MARGIN_BOTTOM,
  HEADING_600_FONT_FAMILY: DSToken.HEADING_600_FONT_FAMILY,
  HEADING_600_FONT_SIZE: DSToken.HEADING_600_FONT_SIZE,
  HEADING_600_FONT_WEIGHT: DSToken.HEADING_600_FONT_WEIGHT,
  HEADING_600_LINE_HEIGHT: DSToken.HEADING_600_LINE_HEIGHT,
  HEADING_600_MARGIN_BOTTOM: DSToken.HEADING_600_MARGIN_BOTTOM,
  HEADING_RESPONSIVE_400_FONT_SIZE: DSToken.HEADING_RESPONSIVE_400_FONT_SIZE,
  HEADING_RESPONSIVE_500_FONT_SIZE: DSToken.HEADING_RESPONSIVE_500_FONT_SIZE,
  HEADING_RESPONSIVE_600_FONT_SIZE: DSToken.HEADING_RESPONSIVE_600_FONT_SIZE,
  PARAGRAPH_50_FONT_FAMILY: DSToken.PARAGRAPH_50_FONT_FAMILY,
  PARAGRAPH_50_FONT_SIZE: DSToken.PARAGRAPH_50_FONT_SIZE,
  PARAGRAPH_50_FONT_WEIGHT: DSToken.PARAGRAPH_50_FONT_WEIGHT,
  PARAGRAPH_50_LINE_HEIGHT: DSToken.PARAGRAPH_50_LINE_HEIGHT,
  PARAGRAPH_50_MARGIN_BOTTOM: DSToken.PARAGRAPH_50_MARGIN_BOTTOM,
  PARAGRAPH_100_FONT_FAMILY: DSToken.PARAGRAPH_100_FONT_FAMILY,
  PARAGRAPH_100_FONT_SIZE: DSToken.PARAGRAPH_100_FONT_SIZE,
  PARAGRAPH_100_FONT_WEIGHT: DSToken.PARAGRAPH_100_FONT_WEIGHT,
  PARAGRAPH_100_LINE_HEIGHT: DSToken.PARAGRAPH_100_LINE_HEIGHT,
  PARAGRAPH_100_MARGIN_BOTTOM: DSToken.PARAGRAPH_100_MARGIN_BOTTOM,
  PARAGRAPH_200_FONT_FAMILY: DSToken.PARAGRAPH_200_FONT_FAMILY,
  PARAGRAPH_200_FONT_SIZE: DSToken.PARAGRAPH_200_FONT_SIZE,
  PARAGRAPH_200_FONT_WEIGHT: DSToken.PARAGRAPH_200_FONT_WEIGHT,
  PARAGRAPH_200_LINE_HEIGHT: DSToken.PARAGRAPH_200_LINE_HEIGHT,
  PARAGRAPH_200_MARGIN_BOTTOM: DSToken.PARAGRAPH_200_MARGIN_BOTTOM,
  PARAGRAPH_300_FONT_FAMILY: DSToken.PARAGRAPH_300_FONT_FAMILY,
  PARAGRAPH_300_FONT_SIZE: DSToken.PARAGRAPH_300_FONT_SIZE,
  PARAGRAPH_300_FONT_WEIGHT: DSToken.PARAGRAPH_300_FONT_WEIGHT,
  PARAGRAPH_300_LINE_HEIGHT: DSToken.PARAGRAPH_300_LINE_HEIGHT,
  PARAGRAPH_300_MARGIN_BOTTOM: DSToken.PARAGRAPH_300_MARGIN_BOTTOM,
  TEXT_50_FONT_FAMILY: DSToken.TEXT_50_FONT_FAMILY,
  TEXT_50_FONT_SIZE: DSToken.TEXT_50_FONT_SIZE,
  TEXT_50_FONT_WEIGHT: DSToken.TEXT_50_FONT_WEIGHT,
  TEXT_50_LINE_HEIGHT: DSToken.TEXT_50_LINE_HEIGHT,
  TEXT_100_FONT_FAMILY: DSToken.TEXT_100_FONT_FAMILY,
  TEXT_100_FONT_SIZE: DSToken.TEXT_100_FONT_SIZE,
  TEXT_100_FONT_WEIGHT: DSToken.TEXT_100_FONT_WEIGHT,
  TEXT_100_LINE_HEIGHT: DSToken.TEXT_100_LINE_HEIGHT,
  TEXT_200_FONT_FAMILY: DSToken.TEXT_200_FONT_FAMILY,
  TEXT_200_FONT_SIZE: DSToken.TEXT_200_FONT_SIZE,
  TEXT_200_FONT_WEIGHT: DSToken.TEXT_200_FONT_WEIGHT,
  TEXT_200_LINE_HEIGHT: DSToken.TEXT_200_LINE_HEIGHT,
  TEXT_300_FONT_FAMILY: DSToken.TEXT_300_FONT_FAMILY,
  TEXT_300_FONT_SIZE: DSToken.TEXT_300_FONT_SIZE,
  TEXT_300_FONT_WEIGHT: DSToken.TEXT_300_FONT_WEIGHT,
  TEXT_300_LINE_HEIGHT: DSToken.TEXT_300_LINE_HEIGHT,
} as const;
export default DSTokenMap;
