import type { ComponentType, ReactNode } from 'react';
import React from 'react';
import { flushSync } from 'react-dom';
import { createRoot, Root } from 'react-dom/client';

import ThemeProvider from '@rover/react-lib/src/components/utils/ThemeProvider';
import { RSDKProviders } from '@rover/rsdk/src/';
import ObservabilityMetadata from '@rover/rsdk/src/modules/ObservabilityMetadata';
import inject from '@rover/utilities/injector';
import { i18nConfig } from '@rover/utilities/translation';

export default function renderRootElement<Props>(
  Component: ComponentType<Props> | ReactNode,
  elementSelector: string,
  props?: Props
): Root {
  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
  const rootElement = document.querySelector(elementSelector);
  if (!rootElement) {
    throw new Error(`rootElement not found with elementSelector ${elementSelector}`);
  }

  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
  ObservabilityMetadata.set('currentView', window.location.pathname);
  ObservabilityMetadata.set('locale', i18nConfig.language);
  try {
    ObservabilityMetadata.set('userOPK', inject('Rover.context.person.opk', undefined));
  } catch (e) {
    // this can throw when unauthed, but we don't care
  }

  // We only want to render ReactQueryDevTools once per page, even on a Django page where renderRootElement
  // is being called multiple times for different included React components
  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
  const reactQueryDevToolsExists = document.querySelector('#ReactQueryDevtoolsPanel');

  // start the react app
  const root = createRoot(rootElement);
  // flushSync forces sync rendering of the app https://stackoverflow.com/a/71983073
  // important in django/react apps where we may try to talk to React from other JS after calling renderRootElement
  flushSync(() => {
    root.render(
      <RSDKProviders shouldRenderReactQueryDevTools={!reactQueryDevToolsExists} {...i18nConfig}>
        <ThemeProvider>
          {/* @ts-expect-error */}
          <Component {...props} />
        </ThemeProvider>
      </RSDKProviders>
    );
  });

  // We set min-height on the root container to minimize CLS. Once rendered, we can remove it
  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
  const element = document.querySelector(elementSelector);
  if (element?.getAttribute('data-should-remove-style') === 'true') {
    element?.removeAttribute('style');
  }
  return root;
}
